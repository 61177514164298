import React from 'react'

export const SelectedTemplate = (option, props) => {
    if (option) {
        return (
            <div className="country-item country-item-value">
                <div title={option.name} className="p-text-nowrap p-text-truncate">{option.name}</div>
            </div>
        );
    }

    return (
        <span>
            {props.placeholder}
        </span>
    );
}
