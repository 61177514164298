import CKEditor from 'ckeditor4-react';
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import notification from "helpers/constant/notification";
import React from "react";


const DialogAddEdit = (props) => {
    const {
        showAlert,
        fetData,
        visible,
        onHideDialog,
        serviceMailTemplate,
        typeAd,
        adData,
        setAdData,
        errData,
        setErrData,
    } = props;
    const listSuport = [
        { 'code': '$noi_dung', title: 'Hiển thị nội dung cuộc họp' },
        { 'code': '$yeu_cau', title: 'Hiển thị yêu cầu chuẩn bị cuộc họp' },
        { 'code': '$text_lap', title: 'Hiển thị ngày bắt đầu lặp' },
        { 'code': '$ngay', title: 'Hiển thị ngày kết thúc lặp' },
        { 'code': '{! !}', title: 'Kí tự bắt buộc để hiển thị chuỗi lặp' },
    ]
    const updateField = (data, field) => {
        setAdData({
            ...adData,
            [field]: data,
        });
    };
    const saveData = async () => {
        if (typeAd === 1) {
            const result = await serviceMailTemplate.create(adData);
            const { error, message,status } = result;
            try {
                if (status === 2) {
                    if (error) {
                        showAlert(
                            "error",
                            notification.CREATE_FAIL,
                            notification.MESSAGE_VALIDATE
                        );
                        setErrData(error);
                    } else {
                        showAlert("error", notification.CREATE_FAIL, message);
                    }
                    return;
                } 
                if (status === 1) {
                    showAlert("success", notification.CREATE_SUCCESS, message);
                    fetData();
                    onHideDialog();
                }
            } catch (error) {
                showAlert(
                    "error",
                    notification.CREATE_FAIL,
                    notification.MESSAGE_ERROR
                );
            }
        } else {
            try {
                const result = await serviceMailTemplate.update(adData);
                const { error, message,status } = result;
                if (status === 2) {
                    if (error) {
                        showAlert(
                            "error",
                            notification.UPDATE_FAIL,
                            notification.MESSAGE_VALIDATE
                        );
                        setErrData(error);
                    } else {
                        showAlert("error", notification.UPDATE_FAIL, message);
                    }
                    return
                }
                if (status === 1) {
                    showAlert("success", notification.UPDATE_SUCCESS, message);
                    fetData();
                    onHideDialog();
                }
            } catch (error) {
                showAlert(
                    "error",
                    notification.UPDATE_FAIL,
                    notification.MESSAGE_ERROR
                );
            }
        }
    };
    const dialogFooter = (
        <React.Fragment>
            <Button
                label="Hủy"
                icon="pi pi-times"
                className="p-button-text p-button-raised"
                onClick={onHideDialog}
            />
            <Button
                label="Lưu"
                icon="pi pi-check"
                className="p-button-raised"
                onClick={saveData}
            />
        </React.Fragment>
    );
    return (
        <div className="category-mail__add">
            <Dialog
                visible={visible}
                header={
                    typeAd === 1 ? "Tạo mới mẫu Email" : "Cập nhật mẫu Email"
                }
                modal
                className="p-fluid p-dialog-default p-dialog-lg wr--50"
                footer={dialogFooter}
                onHide={onHideDialog}
            >
                <div className="p-field">
                    <label htmlFor="code" className="label-bold">
                        Mã mẫu mail <span className="text-danger">*</span>
                    </label>
                    <InputText
                        id="code"
                        value={adData.code}
                        disabled
                        placeholder="Nhập mã mẫu mail"
                        onChange={(e) => updateField(e.target.value, "code")}
                    />
                    <span id="username2-help" className="p-invalid p-d-block">
                        {errData.code}
                    </span>
                </div>
                <div className="p-field">
                    <label htmlFor="name" className="label-bold">
                        Tên mẫu mail <span className="text-danger">*</span>
                    </label>
                    <InputText
                        id="name"
                        value={adData.name}
                        placeholder="Nhập tên mẫu mail"
                        onChange={(e) => updateField(e.target.value, "name")}
                    />
                    <span id="username2-help" className="p-invalid p-d-block">
                        {errData.name}
                    </span>
                </div>
                <div className="p-field">
                    <label htmlFor="title" className="label-bold">
                        Tiêu đề mẫu mail <span className="text-danger">*</span>
                    </label>
                    <InputText
                        id="title"
                        value={adData.title}
                        placeholder="Nhập tên mẫu mail"
                        onChange={(e) => updateField(e.target.value, "title")}
                    />
                    <span id="username2-help" className="p-invalid p-d-block">
                        {errData.title}
                    </span>
                </div>
                <div className="p-field">
                    <label htmlFor="content" className="label-bold">Nội dung file</label>
                    <CKEditor id="content"
                        data={adData.content}
                        className="ck-editor"
                        onChange={(e) => updateField(e.editor.getData(), 'content')} name="content" />
                    <span id="username2-help" className="p-invalid p-d-block">
                        {errData.content}
                    </span>
                </div>
                {adData.code !== "mail.reset_password" &&
                    <div className="p-field">
                        <h6 className="p-text-center">Bảng chú thích nội dung file</h6>
                        <DataTable
                            value={listSuport}
                            dataKey="id"
                            className="p-datatable-gridlines table-word-break p-datatable-responsive-demo"
                            emptyMessage="Không có data"
                        >
                            <Column field="code" header="Code"
                                // className="stt-table"
                                className="p-text-center" sortable
                            />
                            <Column field="title" header="Chú thích" sortable />
                        </DataTable>
                    </div>
                }
                <div className="p-field">
                    <label htmlFor="description" className="label-bold">Mô tả</label>
                    <InputTextarea
                        id="description"
                        value={adData.description ||""}
                        placeholder="Nhập mô tả"
                        onChange={(e) =>
                            updateField(e.target.value, "description")
                        }
                        rows={3}
                        cols={20}
                    />
                    <span id="username2-help" className="p-invalid p-d-block">
                        {errData.description}
                    </span>
                </div>
            </Dialog>
        </div>
    );
};
export default (DialogAddEdit);
