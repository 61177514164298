import FetchAPI from "../helpers/FetchAPI";
import { TypeMethodConstantEnum } from "../helpers/MethodAPIConstant";

class DashboardServices extends FetchAPI {
    public async get(data) {
        let url = `dashboard/index`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }
}
export default DashboardServices;
