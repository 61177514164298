import React from 'react'
import { useApp } from "context/AppContext";

const ViewPdfIframe = (props) => {
    const { heightScreen } = useApp();

    const  {html}= props;
    return (
        <div className="ViewPdfIframe">
                <iframe
                src={html}
                height="800" title="viewPdf"
                width={heightScreen-100}
                loading="lazy"
                ></iframe>
        </div>
    )
}


export default ViewPdfIframe
