
function validateAcceptFile(fileName, acceptFile) {
    let extensionFile = fileName.substr(fileName.lastIndexOf('.') + 1);
    if (!acceptFile.includes(extensionFile)) {
        return false
    } else {
        return true;
    }
}
export default validateAcceptFile;
