import { useApp } from 'context/AppContext';
import notification from 'helpers/constant/notification';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import Service from 'services/UserServices';

export const DialogPro = (props) => {
    const { showAlert } = useApp();
    const service = new Service();
    const emptyData = {
        phone: "",
        fullname: "",
    }
    const [dataSave, setDataSave] = useState(emptyData);
    const handleChangeInput = (e) => {
        setDataSave({ ...dataSave, [e.target.name]: e.target.value });
    }
    const [dataError, setDataError] = useState(emptyData);
    const getDataEdit = () => {
        if (props.dataViewInfo) {
            setDataSave({
                phone: props.dataViewInfo.phone,
                fullname: props.dataViewInfo.fullname,
            })
        }
    }

    const onEditClick = async () => {
        try {
            const result = await service.updateCurrentUser(dataSave);
            const { error, message, status } = result;
            if (status === 2) {
                if (error) {
                    setDataError(error)
                    showAlert('error', notification.UPDATE_FAIL, notification.MESSAGE_VALIDATE);
                } else {
                    showAlert('error', notification.UPDATE_FAIL, message);
                }
                return;
            }
            if (status === 1) {
                showAlert('success', notification.UPDATE_SUCCESS, message);
                props.onHideDialog();
                props.fetData();
            }
        } catch (error) {
            showAlert('error', notification.UPDATE_FAIL, notification.MESSAGE_ERROR);
        }
    }

    useEffect(() => {
        getDataEdit();
        // eslint-disable-next-line
    }, [props.dataViewInfo]);

    const productDialogFooter = (
        <React.Fragment>
            <Button label="Hủy" icon="pi pi-times" className="p-button-text" onClick={props.onHideDialog} />
            <Button label="Lưu" icon="pi pi-check" onClick={onEditClick} />
        </React.Fragment>
    );

    return (
        <div>
            <Dialog visible={props.visible} header={"Cập nhật thông tin"} modal className="p-fluid p-dialog-default" footer={productDialogFooter}
                onHide={props.onHideDialog}>
                <div className="p-grid">
                    <div className="p-col-12 p-md-6 p-field">
                        <label htmlFor="fullname" className="label-bold">Họ tên <span className="text-danger">*</span></label>
                        <InputText id="fullname" value={dataSave.fullname}
                            onChange={handleChangeInput}
                            name="fullname"
                            placeholder="Nhập họ tên"
                            autoFocus
                        />
                        <span className="p-invalid">{dataError.fullname}</span>
                    </div>
                    <div className="p-col-12 p-md-6 p-field">
                        <label htmlFor="phone" className="label-bold">Số điện thoại</label>
                        <InputText id="phone" value={dataSave.phone}
                            onChange={handleChangeInput} name="phone"
                            placeholder="Nhập số điện thoại"
                        />
                        <span className="p-invalid">{dataError.email}</span>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

DialogPro.propTypes = {
    visible: PropTypes.bool.isRequired,
    onHideDialog: PropTypes.func.isRequired,
    dataViewInfo: PropTypes.object,
    fetData: PropTypes.func.isRequired,
}
