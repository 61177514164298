import { setAuthData } from 'app/actions';
import Axios from "axios";
import { useApp } from "context/AppContext";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { Password } from 'primereact/password';
import React, { useState } from "react";
import { connect } from "react-redux";
import { authenticationService } from "services/AuthenticationService";
import LoginHooks from "./LoginHooks";

const Login = (props) => {
    const { showAlert } = useApp();

    const [errData, setErrData] = useState({});
    const [data, setData] = useState({
        email: "",
        password: "",
    });

    const updateField = (dataFrom, field) => {
        setData({
            ...data,
            [field]: dataFrom,
        });
    };

    const onSubmit = async (e) => {
        try {
            const BASE_URL = process.env.REACT_APP_BASE_API_URL;
            e.preventDefault();
            const user = await authenticationService.login(data);
            if (user.token) {
                const url = BASE_URL + "tai-khoan/getUserInfo";
                Axios.get(`${url}`, {
                    headers: {
                        Authorization: "Bearer " + user.token, //the token is a variable which holds the token
                    },
                })
                    .then(function (e) {
                        props.setAuthData(e.data);
                    })
                    .catch(function (e) {
                        console.error('e: ', e);
                     });
                window.location.hash = "/";
            }
            if (user && user.status === 2) {
                if (user.errors) {
                    setErrData(user.errors);
                } else {
                    setErrData({
                        email: user.message,
                        password: user.message,
                    });
                }
                showAlert("warn", "Đăng nhập thất bại", user.message);
            }
        } catch (error) {
            showAlert("warn", "Đăng nhập thất bại", "Có lỗi xảy ra");
        }

    };

    return (
        <div className="login-wrap">
            <div className="login-html">
                <div className="login-form">
                    <div className="card">
                        <form onSubmit={(e) => onSubmit(e)}

                        >
                            <h1 className="header-login p-text-center p-mb-6 ">
                                <img
                                    src="showcase/images/cyber-joom-logo2.png"
                                    alt="cyber-joom-login"
                                    className="logo-login"
                                    width="350"
                                    height="44"
                                />
                            </h1>
                            <label
                                htmlFor="email"
                                className="font-size-17 label-login"
                            >
                                Email
                            </label>
                            <div className="p-fluid">
                                <div className="p-inputgroup p-mb-4 ">
                                    <div className="p-float-label">
                                        <InputText
                                            id="email"
                                            name="email"
                                            type="text"
                                            value={data.email}
                                            onChange={(e) => updateField(e.target.value, 'email')}
                                            className=" p-d-block font-size-15 input-login"
                                            autoComplete="current-password"
                                        />

                                    </div>
                                </div>
                                <div
                                    id="email"
                                    className="p-invalid p-d-block p-mb-4"
                                >
                                    {errData.email}
                                </div>
                                <label
                                    htmlFor="password"
                                    className="font-size-17 label-login"
                                >
                                    Mật khẩu
                                </label>
                                <div className="p-inputgroup p-mb-4 ">
                                    <div className="p-float-label">
                                        <Password
                                            id="password"
                                            name="password"
                                            value={data.password}
                                            onChange={(e) => updateField(e.target.value, 'password')}
                                            className="p-d-block font-size-15 input-login"
                                            toggleMask
                                            feedback={false}
                                            autoComplete="current-password"
                                        />
                                    </div>
                                </div>
                                <div
                                    id="password"
                                    className="p-invalid p-d-block p-mb-2"
                                >
                                    {errData.password}
                                </div>
                            </div>
                            <div className="p-mt-5 p-flex p-justify-center">
                                <Button
                                    label="Đăng nhập"
                                    type="submit"
                                    className="
                                p-button p-button-lg btn-login p-button-text
                                "
                                    onClick={(e) => onSubmit(e)}
                                />
                            </div>
                        </form>
                        <Divider align="center">
                            <div className="p-d-inline-flex p-ai-center">
                                <b className="font-size-17">Hoặc</b>
                            </div>
                        </Divider>
                        <LoginHooks setAuthData={props.setAuthData} />
                    </div>
                </div>
            </div>
        </div>
    );
};
function mapStateToProps(state) {
    const { authReducer } = state;
    return { userData: authReducer };
}

const mapDispatchToProps = {
    setAuthData,
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);
