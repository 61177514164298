import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import notification from 'helpers/constant/notification'
import titleDefault from 'helpers/constant/titleDefault'
import React from 'react'

const DialogAddEdit = props => {
    const { showAlert, fetData, visible, onHideDialog, serviceDevice, typeAd,
        adData, setAdData, errData, setErrData } = props;

    const updateField = (data, field) => {
        setAdData({
            ...adData,
            [field]: data,
        });
    };
    const saveData = async () => {
        if (typeAd === 1) {
            try {
                const result = await serviceDevice.create(adData);
                const { error, message,status } = result;
                if (status === 2) {
                    if (error) {
                        showAlert('error', notification.CREATE_FAIL, notification.MESSAGE_VALIDATE);
                        setErrData(error);
                    } else {
                        showAlert('error', notification.CREATE_FAIL, message);
                    }
                    return;
                }
                if (status === 1) {
                    showAlert('success', notification.CREATE_SUCCESS, message);
                    fetData();
                    onHideDialog();
                }
            } catch (error) {
                showAlert('error', notification.CREATE_FAIL, notification.MESSAGE_ERROR);
            }
        } else {
            try {
                const result = await serviceDevice.update(adData);
                const { error, message,status } = result;
                if (status === 2) {
                    if (error) {
                        showAlert('error', notification.UPDATE_FAIL, notification.MESSAGE_VALIDATE);
                        setErrData(error);
                    } else {
                        showAlert('error', notification.UPDATE_FAIL, message);
                    }
                    return;
                }
                if (status === 1) {
                    showAlert('success', notification.UPDATE_SUCCESS, message);
                    fetData();
                    onHideDialog();
                }
            } catch (error) {
                showAlert('error', notification.UPDATE_FAIL, notification.MESSAGE_ERROR);
            }
        }
    };
    const dialogFooter = (
        <React.Fragment>
            <Button label="Hủy" icon="pi pi-times" className="p-button-text p-button-raised" onClick={onHideDialog} />
            <Button label="Lưu" icon="pi pi-check" className="p-button-raised" onClick={saveData} />
        </React.Fragment>
    );
    return (
        <div>
            <Dialog visible={visible}
                 header={typeAd === 1 ? titleDefault.DEVICE_CREAT : titleDefault.DEVICE_UPDATE}
                modal className="p-fluid p-dialog-default wr--30"
                footer={dialogFooter} onHide={onHideDialog}>
                <div className="p-field">
                    <label htmlFor="name" className="label-bold">Tên thiết bị <span className="text-danger">*</span></label>
                    <InputText id="name" value={adData.name}
                        placeholder="Nhập tên thiết bị"
                        onChange={(e) => updateField(e.target.value, 'name')} />
                    <span id="username2-help" className="p-invalid p-d-block">{errData.name}</span>
                </div>
                <div className="p-field">
                    <label htmlFor="description" className="label-bold">Mô tả</label>
                    <InputTextarea id="description" value={adData.description||""}
                        placeholder="Nhập mô tả"
                        onChange={(e) => updateField(e.target.value, 'description')} rows={3} cols={20} />
                    <span id="username2-help" className="p-invalid p-d-block">{errData.description}</span>
                </div>
            </Dialog>
        </div>
    )
}
export default (DialogAddEdit);
