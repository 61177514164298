import store from "app/store";

let typeRole = 2;
const checkPermission = (permission) => {
    const userPer = store.getState().authReducer.userData;
    if (userPer) {
        const listPer = userPer.permission;
        if (listPer.indexOf(permission) !== -1) {
            typeRole = 1;
        }else{
            typeRole = 2;
        }
    }
    return typeRole;
}

export default checkPermission
