import { Checkbox } from "primereact/checkbox";
import { Chips } from "primereact/chips";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import InputUploadFile from "components/upload-file/InputUploadFile";
import notification from "helpers/constant/notification";
import titleDefault from "helpers/constant/titleDefault";
import typeRepeat from "helpers/constant/typeRepeat";
import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import MyBookServices from "services/MyBookServices";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";

export const DialogScheduleAll = (props) => {
    const serviceBook = new MyBookServices();
    const acceptFileString = ".pdf,.jpg,.png,.jpeg,.doc,.docx,.xls,.xlsx,.ppt,.pptx";

    const { time, visible, onHideDialog, typeAd, adData, setAdData, errData, setErrData, listMeeting, listUser, showAlert,
        fetData, edData, typeUpdate, hideRepeat, dataConfig, files } = props;
    useEffect(() => {
        getEditData();
        // eslint-disable-next-line
    }, [edData]);
    const refFileProcedure = useRef(null);

    const getEditData = () => {
        try {
            if (edData) {
                let newListUser = [];
                let listUserRoom;
                if (edData.user_list.length > 0) {
                    listUserRoom = JSON.parse(edData.user_list);
                }
                listUser.map(function (e) {
                    if (listUserRoom.indexOf(e.code) !== -1) {
                        newListUser.push(e);
                    }
                    return newListUser;
                });
                edData.user_list = newListUser;
                setAdData(edData);
            }
        } catch (error) {
            console.error('error: ', error);
            showAlert("error", notification.FAIL, notification.MESSAGE_ERROR);
        }
    };
    const updateField = (key, field) => {
        setAdData({
            ...adData,
            [field]: key,
        });
    };
    const onUploadFile = (file_upload) => {
        let file_attach = [];
        file_upload.map((e) => {
            file_attach.push(e.nameUp);
            return e;
        });
        setAdData({
            ...adData,
            file_attach,
        })
    }
    const changeStartTime = (e) => {
        try {
            let minutesAdd = 30;
            if (dataConfig.default_time_meeting === 1) {
                minutesAdd = 30;
            } else if (dataConfig.default_time_meeting === 2) {
                minutesAdd = 60;
            } else if (dataConfig.default_time_meeting === 3) {
                minutesAdd = 90;
            } else if (dataConfig.default_time_meeting === 4) {
                minutesAdd = 120;
            }
            const endTime = moment(e.target.value.code, "hh:mm:ss").add(minutesAdd, "minutes").format("HH:mm");
            const end_time = { code: endTime, name: endTime };

            setAdData({
                ...adData,
                end_time: end_time,
                start_time: e.target.value,
            });
        } catch (e) {
            setAdData({
                ...adData,
                start_time: null,
            });
        }
    };
    const [dialogDeleteS, setDialogDeleteS] = useState(false);
    const [dialogUpdateS, setDialogUpdateS] = useState(false);
    const onShowDeleteDialogS = () => {
        setDialogDeleteS(true);
    };
    const onShowUpdateDialogS = () => {
        setDialogUpdateS(true);
    };
    const onHideDialogDeleteS = () => {
        setDialogDeleteS(false);
    };

    const onHideDialogUpdateS = () => {
        setDialogUpdateS(false);
    };

    const saveData = async (e) => {
        let newAdData = { ...adData };
        // thông tin phòng
        if (adData.room_id) {
            newAdData = { ...newAdData, room_id: adData.room_id.code };
        }
        // thời gian bắt đầu
        if (adData.start_time) {
            newAdData = { ...newAdData, start_time: adData.start_time.code };
        }
        // thời gian kết thúc
        if (adData.end_time) {
            newAdData = { ...newAdData, end_time: adData.end_time.code };
        }
        // loại lặp
        if (adData.type_repeat) {
            newAdData = { ...newAdData, type_repeat: adData.type_repeat.code };
        }
        // ngày kết thúc lặp
        if (adData.end_date_repeat) {
            let newEndate = moment(adData.end_date_repeat).format("YYYY-MM-DD");
            newAdData = { ...newAdData, end_date_repeat: newEndate };
        }
        // ngày đặt lịch
        if (adData.date) {
            let newDate = moment(adData.date).format("YYYY-MM-DD");
            newAdData = { ...newAdData, date: newDate };
        }
        // người đặt lịch
        if (adData.user_id) {
            newAdData = { ...newAdData, user_id: adData.user_id.code };
        }
        // danh sách đặt lịch
        if (adData.user_list) {
            let listUser = adData.user_list.map((r) => r.code);
            newAdData = { ...newAdData, user_list: listUser };
        }
        newAdData = { ...newAdData, repeat: adData.repeat + 1 };

        if (typeAd === 1) {
            try {
                const result = await serviceBook.create(newAdData);
                const { error, message } = result;
                if (result && result.status === 2) {
                    if (error) {
                        showAlert("error", notification.CREATE_FAIL, notification.MESSAGE_VALIDATE);
                        setErrData(error);
                    } else {
                        showAlert("error", notification.CREATE_FAIL, message);
                    }
                } else {
                    showAlert("success", notification.CREATE_SUCCESS, message);
                    fetData();
                    onHideDialog();
                }
            } catch (error) {
                showAlert("error", notification.CREATE_FAIL, notification.MESSAGE_ERROR);
            }
        } else {
            newAdData = { ...newAdData, type_update: e };
            try {
                const result = await serviceBook.update(newAdData);
                const { error, message } = result;
                if (result && result.status === 2) {
                    if (error) {
                        showAlert("error", notification.UPDATE_FAIL, notification.MESSAGE_VALIDATE);
                        onHideDialogUpdateS();
                        setErrData(error);
                    } else {
                        showAlert("error", notification.UPDATE_FAIL, message);
                    }
                } else {
                    showAlert("success", notification.UPDATE_SUCCESS, message);
                    fetData();
                    onHideDialog();
                    onHideDialogUpdateS();
                }
            } catch (error) {
                showAlert("error", notification.UPDATE_FAIL, notification.MESSAGE_ERROR);
            }
        }
    };
    const deleteData = async (e) => {
        let newData = { ...adData, type_update: e };
        try {
            const response = await serviceBook.delete(newData);
            const { status, message } = response;
            onHideDialog();
            onHideDialogDeleteS();
            if (status === 2) {
                showAlert("error", notification.DELETE_FAIL, message);
            } else {
                showAlert("success", notification.DELETE_SUCCESS, message);
                fetData();
            }
        } catch (error) {
            onHideDialog();
            onHideDialogDeleteS();
            showAlert("error", notification.DELETE_FAIL, notification.MESSAGE_ERROR);
        }
    };

    const dialogFooterAdEdit = (
        adData.typeUpdate === 1 &&
        <React.Fragment>
            <div className="p-d-flex">
                <div className="">
                    {typeAd !== 1 && (
                        <Button label="Xóa" icon="pi pi-trash" className="p-button-raised p-button-danger"
                            onClick={onShowDeleteDialogS}
                        />
                    )}
                </div>
                <div className="p-ml-auto">
                    <Button label="Hủy" icon="pi pi-times" className="p-button-text p-button-raised"
                        onClick={onHideDialog}
                    />
                    <Button
                        label={typeAd === 1 ? "Lưu" : "Cập nhật"}
                        icon="pi pi-check" className="p-button-raised"
                        onClick={typeAd === 1 ? saveData : onShowUpdateDialogS}
                    />
                </div>
            </div>
        </React.Fragment>

    );

    const DialogFooter = (
        <React.Fragment>
            <Button label="Hủy" icon="pi pi-times" className="p-button-text p-button-raised"
                onClick={onHideDialogDeleteS}
            />
            <Button label="Xóa" icon="pi pi-check" className="p-button-danger p-button-raised"
                onClick={() => deleteData(1)}
            />
            {typeUpdate !== null ? (
                <Button label="Xóa nhiều " icon="pi pi-check-circle" className="p-button-danger p-button-raised"
                    onClick={() => deleteData(2)}
                />
            ) : (
                ""
            )}
        </React.Fragment>
    );
    //diaglog update nhiều
    const DialogUpdateFooter = (
        <React.Fragment>
            <Button label="Hủy" icon="pi pi-times" className="p-button-text p-button-raised"
                onClick={onHideDialogUpdateS}
            />
            <Button label="Cập nhật" icon="pi pi-check" className="p-button-raised"
                onClick={() => saveData(1)}
            />
            {typeUpdate !== null ? (
                <Button label="Cập nhật nhiều " icon="pi pi-check-circle" className="p-button-raised"
                    onClick={() => saveData(2)}
                />
            ) : (
                ""
            )}
        </React.Fragment>
    );

    return (
        <div className="DialogScheduleAll">
            {adData.typeView === 1 &&
                <Dialog visible={visible}
                    header={typeAd === 1 ? titleDefault.SCHEDULE_CREATE : titleDefault.SCHEDULE_UPDATE}
                    modal className="p-fluid p-dialog-default p-dialog-lg wr-md-60"
                    footer={dialogFooterAdEdit}
                    onHide={onHideDialog}
                    maximizable
                >
                    <div className="p-grid">
                        <div className="p-col-12 p-md-6">
                            <div className=" p-field">
                                {/* nhập tiêu đề */}
                                <label htmlFor="title" className="align-sub-text label-bold">
                                    Tiêu đề <span className="text-danger">*</span>
                                </label>
                                <InputText id="title" value={adData.title} placeholder="Tiêu đề"
                                    onChange={(e) => updateField(e.target.value, "title")}
                                    disabled={adData.typeUpdate === 2 ? true : false}
                                />
                                <span id="username2-help" className="p-invalid p-d-block" >{errData.title}</span>
                            </div>
                            <div className=" p-field">
                                {/* chọn phòng họp */}
                                <label htmlFor="room_id" className="align-sub-text label-bold">
                                    Phòng họp <span className="text-danger">*</span>
                                </label>
                                <Dropdown id="room_id" value={adData.room_id} options={listMeeting}
                                    onChange={(e) => updateField(e.value, "room_id")}
                                    optionLabel="name" filter showClear
                                    filterBy="name" placeholder="Chọn phòng họp"
                                    disabled={adData.typeUpdate === 2 ? true : false}
                                />
                                <span id="username2-help" className="p-invalid p-d-block">{errData.room_id}</span>
                            </div>
                            {/* thời gian bắt đầu */}
                            <div className="p-field">
                                <label htmlFor="start_date" className="align-sub-text label-bold">
                                    Bắt đầu <span className="text-danger">*</span>
                                </label>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-6 ">
                                        <div className="p-inputgroup">
                                            <Calendar id="start_date" value={adData.date}
                                                onChange={(e) => { updateField(e.value, "date") }}
                                                placeholder="Chọn ngày bắt đầu"
                                                dateFormat="dd/mm/yy"
                                                disabled={adData.typeUpdate === 2 ? true : false}
                                            />
                                            <span className="p-inputgroup-addon">
                                                <i className="pi pi-calendar"></i>
                                            </span>
                                        </div>
                                        <span id="username2-help" className="p-invalid p-d-block">{errData.date}</span>
                                    </div>
                                    <div className="p-col-12 p-md-6 ">
                                        <div className="p-inputgroup">
                                            <Dropdown id="date" value={adData.start_time}
                                                options={time}
                                                onChange={(e) => changeStartTime(e)}
                                                optionLabel="name" filter
                                                showClear filterBy="name"
                                                placeholder="Chọn giờ bắt đầu"
                                                disabled={adData.typeUpdate === 2 ? true : false}

                                            />
                                            <span className="p-inputgroup-addon">
                                                <i className="pi pi-clock"></i>
                                            </span>
                                        </div>
                                        <span id="username2-help" className="p-invalid p-d-block">{errData.start_time}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="p-field">
                                {/* thời gian kết thúc */}
                                <label htmlFor="display_name" className="align-sub-text label-bold">
                                    Kết thúc <span className="text-danger">*</span>
                                </label>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-6 ">
                                        <div className="p-inputgroup">
                                            <Calendar id="end_date" value={adData.date}
                                                onChange={(e) => { updateField(e.value, "date") }}
                                                placeholder="Chọn ngày kết thúc"
                                                dateFormat="dd/mm/yy"
                                                disabled={adData.typeUpdate === 2 ? true : false}
                                            />
                                            <span className="p-inputgroup-addon">
                                                <i className="pi pi-calendar"></i>
                                            </span>
                                        </div>
                                        <span id="username2-help" className="p-invalid p-d-block" >{errData.date}</span>
                                    </div>
                                    <div className="p-col-12 p-md-6 ">
                                        <div className="p-inputgroup">
                                            <Dropdown id="end_time" value={adData.end_time} options={time}
                                                onChange={(e) => updateField(e.target.value, "end_time")}
                                                optionLabel="name" filter
                                                showClear filterBy="name"
                                                placeholder="Chọn giờ kết thúc"
                                                disabled={adData.typeUpdate === 2 ? true : false}
                                            />
                                            <span className="p-inputgroup-addon">
                                                <i className="pi pi-clock"></i>
                                            </span>
                                        </div>
                                        <span id="username2-help" className="p-invalid p-d-block">
                                            {errData.end_time}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                {/* Lặp lại lịch đặt */}
                                {hideRepeat === 1 && (
                                    <>
                                        <label htmlFor="" className="" >
                                        </label>
                                        <Checkbox inputId="repeat" checked={adData.repeat}
                                            onChange={(e) => updateField(e.checked, "repeat")}
                                            disabled={adData.typeUpdate === 2 ? true : false}
                                        />
                                        <label htmlFor="repeat" className="align-sub-text p-pl-2 label-bold" >
                                            Đặt lịch định kỳ
                                        </label>
                                        <span id="username2-help" className="p-invalid p-d-block">{errData.repeat}</span>
                                        {!adData.repeat &&
                                            <div className="p-mb-3"></div>
                                        }
                                        <div className={`p-grid p-pt-2 ${adData.repeat ? "on-show" : "on-hide"}`}>
                                            <div className="p-col-12 p-md-6 p-field">
                                                <label className="align-sub-text label-bold" htmlFor="type_repeat">
                                                    Lặp lại:{" "}
                                                </label>
                                                <Dropdown id="type_repeat" name="type_repeat"
                                                    value={adData.type_repeat} placeholder="Chọn kiểu lặp"
                                                    options={typeRepeat}
                                                    onChange={(e) => updateField(e.target.value, "type_repeat")}
                                                    optionLabel="name" filter
                                                    showClear filterBy="type_repeat"
                                                    tooltipOptions={{ position: "top" }}
                                                    disabled={adData.typeUpdate === 2 ? true : false}
                                                />
                                                <span id="username2-help" className="p-invalid p-d-block">{errData.type_repeat}</span>
                                            </div>
                                            <div className="p-col-12 p-md-6 p-field">
                                                <label className="align-sub-text label-bold" htmlFor="end_date_repeat">
                                                    Cho đến:{" "}
                                                </label>
                                                <div className="p-inputgroup">
                                                    <Calendar id="end_date_repeat"
                                                        value={adData.end_date_repeat}
                                                        onChange={(e) => { updateField(e.value, "end_date_repeat") }}
                                                        placeholder="Chọn ngày kết thúc"
                                                        disabled={adData.typeUpdate === 2 ? true : false}
                                                    />
                                                    <span className="p-inputgroup-addon">
                                                        <i className="pi pi-calendar"></i>
                                                    </span>
                                                </div>
                                                <span id="username2-help" className="p-invalid p-d-block">{errData.end_date_repeat}</span>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className=" p-field">
                                {/* mô tả phòng họp */}
                                <label htmlFor="display_name" className="align-sub-text label-bold">
                                    Nội dung cuộc họp
                                </label>
                                <InputTextarea id="description"
                                    value={adData.description ||""} placeholder="Nội dung cuộc họp"
                                    onChange={(e) => updateField(e.target.value, "description")}
                                    rows={5} cols={30}
                                    disabled={adData.typeUpdate === 2 ? true : false}
                                />
                                <span id="username2-help" className="p-invalid p-d-block">
                                    {errData.description}
                                </span>
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <div className=" p-field">
                                {/* Người đặt phòng */}
                                <label htmlFor="user_id" className="align-sub-text label-bold">
                                    Người đặt phòng{" "}
                                    <span className="text-danger">*</span>
                                </label>
                                <Dropdown id="user_id" value={adData.user_id}
                                    options={listUser}
                                    onChange={(e) => updateField(e.target.value, "user_id")}
                                    optionLabel="name" filter
                                    showClear filterBy="name" placeholder="Người đặt phòng"
                                    disabled={adData.typeUpdate === 2 ? true : false}
                                />
                                <span id="username2-help" className="p-invalid p-d-block">{errData.user_id}</span>
                            </div>
                            <div className=" p-field">
                                {/* Người tham gia cuộc họp */}
                                <label htmlFor="user_id" className="align-sub-text label-bold">
                                    Người tham gia cuộc họp{" "}
                                    <span className="text-danger">*</span>
                                </label>
                                <MultiSelect value={adData.user_list} options={listUser}
                                    id="user_list"
                                    onChange={(e) => updateField(e.target.value, "user_list")}
                                    optionLabel="name" placeholder="Chọn người tham gia họp"
                                    filter
                                    maxSelectedLabels={1}
                                    selectedItemsLabel="{0} người được chọn"
                                    disabled={adData.typeUpdate === 2 ? true : false}
                                />
                                <span id="username2-help" className="p-invalid p-d-block">{errData.user_list}</span>
                            </div>
                            <div className=" p-field">
                                {/* Người tham gia cuộc họp ngoài hệ thống*/}
                                <label htmlFor="user_id" className="align-sub-text label-bold">
                                    Người tham gia cuộc họp ngoài hệ thống{" "}
                                </label>
                                <Chips value={adData.user_list_other}
                                    onChange={(e) => updateField(e.value, "user_list_other")}
                                    placeholder="Enter để thêm"
                                    className="input-scroll"
                                    disabled={adData.typeUpdate === 2 ? true : false}
                                />
                                <span id="username2-help" className="p-invalid p-d-block" >{errData.user_list_other}</span>
                            </div>
                            <div className=" p-field">
                                {/* tải lên file */}
                                <label htmlFor="file_attach" className="align-sub-text label-bold">
                                    Tải lên file
                                </label>
                                <InputUploadFile
                                    inputId="file_attach"
                                    nameInput="file_attach"
                                    onUploadFile={onUploadFile}
                                    multiple={true}
                                    maxFileSize={10}
                                    chooseLabel="Chọn file"
                                    acceptFile={acceptFileString}
                                    labelViewFile="Danh sách tài liệu"
                                    files={files}
                                    maxFile={5}
                                    disabled={adData.typeUpdate === 2 ? true : false}
                                    refFile={refFileProcedure}
                                />
                                <span id="username2-help" className="p-invalid p-d-block">
                                    {errData.file_attach}
                                </span>
                            </div>
                            <div className=" p-field">
                                {/* mô tả thiết bị trong phòng */}
                                <label htmlFor="request_device" className="align-sub-text label-bold" >
                                    Yêu cầu chuẩn bị
                                </label>
                                <InputTextarea id="request_device" value={adData.request_device||""}
                                    onChange={(e) => updateField(e.target.value, "request_device")}
                                    rows={5} cols={30} placeholder="Yêu cầu chuẩn bị"
                                    disabled={adData.typeUpdate === 2 ? true : false}
                                />

                                <span id="username2-help" className="p-invalid p-d-block" >{errData.request_device}</span>
                            </div>
                        </div>
                    </div>
                </Dialog>
            }
            {
                adData.typeView === 2 &&
                <Dialog visible={visible}
                    header={"Thông tin lịch đặt"}
                    modal className="p-fluid "
                    onHide={onHideDialog}
                >
                    <div className="">
                        <div className=" p-field">
                            {/* nhập tiêu đề */}
                            <label htmlFor="title" className="align-sub-text label-bold">
                                Tiêu đề
                            </label>
                            <InputText id="title" value={adData.title} disabled
                                className="color-input-custom"

                            />
                        </div>
                        <div className=" p-field">
                            {/* chọn phòng họp */}
                            <label htmlFor="room_id" className="align-sub-text label-bold">
                                Phòng họp
                            </label>
                            <Dropdown id="room_id" value={adData.room_id} options={listMeeting}
                                onChange={(e) => updateField(e.value, "room_id")}
                                optionLabel="name" filter
                                filterBy="name" disabled
                                className="color-input-custom"
                            />
                        </div>
                        {/* thời gian bắt đầu */}
                        <div className="p-field">
                            <label htmlFor="start_date" className="align-sub-text label-bold">
                                Bắt đầu
                            </label>
                            <div className="p-grid">
                                <div className="p-col-12 p-md-6 ">
                                    <div className="p-inputgroup">
                                        <Calendar id="start_date" value={adData.date}
                                            dateFormat="dd/mm/yy" disabled
                                            className="color-input-custom"
                                        />
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-calendar"></i>
                                        </span>
                                    </div>
                                </div>
                                <div className="p-col-12 p-md-6 ">
                                    <div className="p-inputgroup">
                                        <Dropdown id="date" value={adData.start_time}
                                            options={time}
                                            optionLabel="name" filter
                                            filterBy="name" disabled
                                            className="color-input-custom"
                                        />
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-clock"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-field">
                            {/* thời gian kết thúc */}
                            <label htmlFor="display_name" className="align-sub-text label-bold">
                                Kết thúc
                            </label>
                            <div className="p-grid">
                                <div className="p-col-12 p-md-6 ">
                                    <div className="p-inputgroup">
                                        <Calendar id="end_date" value={adData.date}
                                            dateFormat="dd/mm/yy" disabled
                                            className="color-input-custom"
                                        />
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-calendar"></i>
                                        </span>
                                    </div>
                                    <span id="username2-help" className="p-invalid p-d-block" >{errData.date}</span>
                                </div>
                                <div className="p-col-12 p-md-6 ">
                                    <div className="p-inputgroup">
                                        <Dropdown id="end_time" value={adData.end_time} options={time}
                                            optionLabel="name" filter
                                            filterBy="name" disabled
                                            className="color-input-custom"
                                        />
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-clock"></i>
                                        </span>
                                    </div>
                                    <span id="username2-help" className="p-invalid p-d-block">
                                        {errData.end_time}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            }
            <Dialog visible={dialogDeleteS} style={{ width: "450px" }}
                header={titleDefault.SCHEDULE_DELETE}
                modal footer={DialogFooter} onHide={onHideDialogDeleteS}
            >
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                    <span> Bạn có chắc chắn muốn xóa?</span>
                </div>
            </Dialog>
            <Dialog visible={dialogUpdateS} style={{ width: "450px" }}
                header={titleDefault.SCHEDULE_UPDATE} modal footer={DialogUpdateFooter}
                onHide={onHideDialogUpdateS}
            >
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                    <span> Bạn có chắc chắn muốn cập nhật?</span>
                </div>
            </Dialog>
        </div>
    );
};
