import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { authenticationService } from 'services/AuthenticationService';


const PrivateRoute = ({ component: Component, ...rest }) => {

    const currentUser = authenticationService.currentUserValue;

    return (
        <div>
            <Route {...rest} render={props => (
                currentUser ?
                    (<Component {...props} />) :
                    <Redirect to="/login" />
            )} />
        </div>
    );
};

export default PrivateRoute;
