import FetchAPI from "../helpers/FetchAPI";
import { TypeMethodConstantEnum } from "../helpers/MethodAPIConstant";

class MyBookServices extends FetchAPI {
    //Lấy danh sách khách hàng
    public async getFilter(data) {
        let url = `lich-dat/index`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }
    public async create(data) {
        let url = `lich-dat/create`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }
    public async update(data) {
        let url = `lich-dat/update`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }
    public async delete(data) {
        let url = `lich-dat/delete`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }
    public async getById(data) {
        let url = `lich-dat/getBookById`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }
}
export default MyBookServices;
