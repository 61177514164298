
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { Toolbar } from 'primereact/toolbar';
import { useApp } from 'context/AppContext';
import checkPermission from 'helpers/checkPermisson';
import pages from 'helpers/constant/pages';
import permissions from 'helpers/constant/permissions';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import DeviceServices from 'services/DeviceServices';
import DialogAddEdit from './DialogAddEdit';
import DialogDelete from './DialogDelete';
import notification from 'helpers/constant/notification';



export const Device = (props) => {

    const emptyData = {
        name: "",
        description: "",
    };
    const { showAlert, pageLimit } = useApp();
    const serviceDevice = new DeviceServices();
    //danh sách
    const [listData, setListData] = useState();
    // dialog xóa
    const [dialogDelete, setDialogDelete] = useState(false);
    // data thêm mới,  xóa
    const [adData, setAdData] = useState(emptyData);
    // check quyền
    const [typeRole, setTypeRole] = useState(1);
    // loại xóa nhiều xóa 1
    const [typeDelete, setTypeDelete] = useState(1);
    // loại thêm mới hay sửa
    const [typeAd, setTypeAd] = useState(1);
    // data lỗi validate
    const [errData, setErrData] = useState({});
    // data phân trang
    let filter = props.history.location.search;
    const [first, setFirst] = useState(0);
    const [pagination, setPagination] = useState({
        from: 0,
        to: 0,
        row: 0,
        total: 0,
        page: 0,
    });
    const [dataSearch, setDataSearch] = useState({
        text_search: "",
    });
    const [row, setRow] = useState(10);
    // dialog thêm, sửa
    const [visibleDialog, setVisibleDialog] = useState(false);
    // data xóa nhiều
    const [selectedDatas, setSelectedDatas] = useState([]);

    useEffect(() => {
        fetData();
        // eslint-disable-next-line
    }, [filter])
    useEffect(() => {
        let typePer = checkPermission(permissions.DEVICE_UPDATE);
        setTypeRole(typePer);
        // eslint-disable-next-line
    }, [])
    const fetData = async () => {
        const query = queryString.parse(props.location.search);
        const response = await serviceDevice.getFilter({ ...dataSearch, ...query });
        if (response) {
            const { data, message, status } = response;
            if (status === 2) {
                showAlert('error', notification.FAIL, message);
                return;
            }
            
            setListData(data.data);
            setPagination({
                from: data.from,
                to: data.to,
                row: data.per_page,
                total: data.total,
                page: data.current_page,
            })
        }
    }

    // action sửa xóa

    // tìm kiếm
    const onSearchClick = (e) => {
        e.preventDefault();
        let jsonDataSearch = queryString.parse(props.history.location.search);
        let pramSearch = { ...jsonDataSearch, text_search: dataSearch.text_search };
        let query = queryString.stringify(pramSearch);
        props.history.push({
            search: query,
        });
    }
    // Phân trang
    const onPageChange = (event) => {
        setFirst(event.first);
        setRow(event.rows);
        let jsonDataSearch = queryString.parse(props.history.location.search);
        let dataSearch = {
            ...jsonDataSearch,
            row: event.rows,
            page: (event.page + 1)
        }
        let query = queryString.stringify(dataSearch);
        props.history.push({
            search: query,
        });
    };
    const handleChangeDataSearch = (e) => {
        setDataSearch({ ...dataSearch, [e.target.name]: e.target.value });
    }
    // f5 lại trang xóa dữ liệu tìm kiếm trên URL
    window.onload = function () {
        props.history.push({
            search: "",
        });
    };
    // dialog thêm mới
    const onCreateClick = () => {
        setAdData(emptyData);
        setVisibleDialog(true);
        setTypeAd(1);
    }
    // dialog sửa
    const onEditClick = (rowData) => {
        setAdData(rowData);
        setTypeAd(2);
        setVisibleDialog(true);
    }
    // export dữ liệu
    //Ẩn dialog thêm mới, cập nhật
    const onHideDialog = () => {
        setErrData({});
        setVisibleDialog(false);
    }

    // bật dialog xóa và tìm data
    const confirmDelete = (rowData) => {
        setTypeDelete(1);
        setAdData(rowData);
        setDialogDelete(true);
    }
    // bật dialog xóa nhiều
    const confirmDeletes = (data) => {
        setTypeDelete(2);
        setDialogDelete(true);
    }
    // xóa nhiều thiết bị và render lại data
    const handelChangeDeletes = () => {
        setSelectedDatas([]);
        fetData();
    }

    // tìm kiếm
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <InputText value={dataSearch.text_search} onChange={e => handleChangeDataSearch(e)}
                    placeholder={"Tìm theo tên hoặc mã"}
                    className=" w-xs-100 w-md-auto p-mb-2"
                    name="text_search" />
            </React.Fragment>
        )
    }
    // Thêm mới, xóa,...
    const rightToolbarTemplate = () => {
        const classSearch = typeRole === 1 ? "p-mr-2" : "";

        return (
            <React.Fragment>
                <div className="p-flex-wrap w--100">
                    <Button label="Tìm kiếm" icon="pi pi-search" type="submit"
                        className={`p-button-primary w-xs-100 w-md-auto p-mb-2 ${classSearch}`}
                        onClick={(e) => onSearchClick(e)} />
                    {typeRole === 1 &&
                        <Button label="Xóa" icon="pi pi-trash" className="p-button-danger p-mr-2
                    p-mb-2  w-xs-100 w-md-auto"
                            onClick={confirmDeletes}
                            disabled={selectedDatas.length === 0 ? true : false}
                        />
                    }
                    {typeRole === 1 &&
                        <Button label="Thêm mới" icon="pi pi-plus" className="p-button-success
                    p-mb-2  w-xs-100 w-md-auto" onClick={onCreateClick} />
                    }
                </div>
            </React.Fragment>
        )
    }
    const renderRowIndex = (rowData, column) => {
        return (
            <React.Fragment>
                <span className="p-column-title">#</span>
                {column.rowIndex + 1 + first}
            </React.Fragment>
        );
    }
    const codeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="p-d-flex">
                    <div className="p-column-title">Mã</div>
                    <div className="max-line-3" title={rowData.code}>
                        {rowData.code}
                    </div>
                </div>
            </React.Fragment>
        );
    };
    const nameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="p-d-flex">
                    <div className="p-column-title">Tên</div>
                    <div className="max-line-3" title={rowData.name}>
                        {rowData.name}
                    </div>
                </div>
            </React.Fragment>
        );
    };
    const descriptionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="p-d-flex">
                    <div className="p-column-title">Mô tả</div>
                    <div className="max-line-3" title={rowData.description}>
                        {rowData.description}
                    </div>
                </div>
            </React.Fragment>
        );
    };
    // action sửa xóa
    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>

                <i className="pi pi-pencil p-mr-2 icon-medium pointer text-success" onClick={() => onEditClick(rowData)}></i>
                <i className="pi pi-trash icon-medium text-danger pointer" onClick={() => confirmDelete(rowData)}></i>
            </React.Fragment>
        );
    }
    return (
        <div className="category-device">
            <div className="content-section implementation datatable-responsive-demo">
                <div className="card">
                    <div className="card-header">
                        <h5>QUẢN LÝ THIẾT BỊ</h5>
                    </div>
                    <form onSubmit={(e) => onSearchClick(e)}>
                        <Toolbar className="p-mb-4 p-d-flex p-flex-wrap"
                            left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    </form>
                    <DataTable
                        rowHover
                        value={listData}
                        dataKey="id"
                        className="p-datatable-gridlines table-word-break p-datatable-responsive-demo"
                        emptyMessage="Không có data"
                        selection={selectedDatas} onSelectionChange={(e) => setSelectedDatas(e.value)}
                    >
                        <Column selectionMode="multiple" className="stt-table checkbox-table" />
                        <Column body={renderRowIndex} header="#" className="stt-table"
                        />
                        <Column field="code" header="Mã" sortable body={codeBodyTemplate} />
                        <Column field="name" header="Tên" sortable body={nameBodyTemplate} />
                        <Column field="description" header="Mô tả" sortable body={descriptionBodyTemplate} />
                        {typeRole === 1 && <Column header="Tác vụ" body={actionBodyTemplate}
                            className="action-table p-text-center"
                        />}

                    </DataTable>
                    {/* phân trang */}
                    <div className="p-d-flex p-flex-wrap">
                        <div className="text-normal p-mt-2">
                            <span>Hiển thị từ <b>{pagination.from}</b> đến <b>{pagination.to}</b> / Tổng <b>{pagination.total}</b> bản ghi</span>
                        </div>
                        <div className="p-ml-auto p-mt-2">
                            <Paginator first={first} rows={row} totalRecords={pagination.total}
                                rowsPerPageOptions={pages} onPageChange={(event) => onPageChange(event)}
                                template=" RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink  "
                                pageLinkSize={pageLimit}
                            />
                        </div>
                    </div>
                </div>
                <DialogAddEdit
                    visible={visibleDialog}
                    onHideDialog={onHideDialog}
                    adData={adData} setAdData={setAdData}
                    serviceDevice={serviceDevice}
                    showAlert={showAlert}
                    errData={errData} setErrData={setErrData}
                    fetData={fetData}
                    typeAd={typeAd}
                />
                {/* Xóa thiết bị */}
                <DialogDelete
                    dialogDelete={dialogDelete}
                    setDialogDelete={setDialogDelete}
                    adData={adData}
                    serviceDevice={serviceDevice}
                    showAlert={showAlert}
                    typeDelete={typeDelete}
                    selectedDatas={selectedDatas}
                    onDeletes={handelChangeDeletes}
                    fetData={fetData}
                />
            </div>
        </div>
    );
}
export default withRouter(Device);
