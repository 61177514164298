import { Button } from 'primereact/button';
import { useApp } from 'context/AppContext';
import React from 'react';
import { Link } from 'react-router-dom';
import { authenticationService } from "services/AuthenticationService";

// /import

export const Page404 = (props) => {
    const { isAuth } = useApp();
    const currentUser = authenticationService.currentUserValue;
    if (!isAuth) {
        if (!currentUser) {
            window.location.hash = "/login";
        }
    }
    return (
        <div className="page-error page-error__404">
            <div className="pg-white p-text-center">
                <div className="page-error__header">404</div>
                <div className="page-error__body">
                    Xin lỗi, trang không tồn tại
                        <div className="p-mt-5">
                        <Link to='/'>
                            <Button
                                className="p-button-lg btn-err"
                                icon="pi pi-home"
                                label="Trang chủ"
                            />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
