import { useApp } from 'context/AppContext';
import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { Button } from 'primereact/button';
import { Paginator } from 'primereact/paginator';
import Service from "services/UserServices";
import { DialogPro } from './DiaLogPro';
import notification from 'helpers/constant/notification';

export const Profile = () => {
    const { showAlert, pageLimit } = useApp();
    const service = new Service();
    const [dataViewInfo, setDataViewInfo] = useState({
        phone: "",
        fullname: "",
        username: "",
        email: "",
    });
    const [dataViewHistory, setDataViewHistory] = useState([]);

    const onHideDialog = () => {
        setVisibleDialog(false);
    };

    const [visibleDialog, setVisibleDialog] = useState(false);
    const [first, setFirst] = useState(0);
    const [totalRecord, setTotalRecord] = useState();
    const [currentPage, setCurrentPage] = useState();
    const onPageChange = (event) => {
        setCurrentPage(event.page + 1)
        setFirst(event.first)
    }

    useEffect(() => {
        fetData();
        fetHistoryData();
        // eslint-disable-next-line
    }, [currentPage]);

    const onEditUser = () => {
        setVisibleDialog(true);
    };

    const fetData = async () => {
        let result = await service.getUserInfo({});
        if (result) {
            const { status, data, message } = result;
            if (status === 2) {
                showAlert('error', notification.FAIL, message);
                return;
            }
            if (status === 1) {
                setDataViewInfo({
                    ...dataViewInfo,
                    'username': data.username,
                    'email': data.email,
                    'fullname': data.fullname,
                    'phone': data.phone,
                    'role': data.user_role.role.display_name,
                    'status': data.status
                });
            }
        }
    };

    const fetHistoryData = async () => {
        let result = await service.getLogHistoryUser(currentPage);
        if (result) {
            const { status, data, message } = result;
            if (status === 2) {
                showAlert('error', notification.FAIL, message);
                return;
            }
            if (status === 1) {
                setDataViewHistory(data.data);
                setTotalRecord(data.total);
            }
        }
    };
    return (
        <div className="content-section implementation formlayout-demo Profile">
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-header p-mb-0">
                        <h5>Thông tin : {dataViewInfo.fullname}</h5>
                    </div>
                </div>
                <div className="p-col-12 p-md-6">
                    <div className="card h--100 ">
                        <div className="p-d-flex">
                            <h5 className="p-d-inline">Chi tiết</h5>
                            <Button label="" icon="pi pi-user-edit" tooltip="Chỉnh sửa"
                                className="p-button-raised p-button-text p-button-plain p-d-inline p-button p-component p-ml-auto p-button-icon-only"
                                onClick={onEditUser} />
                        </div>
                        <div className="p-fluid p-formgrid">
                            <div className="p-col">
                                <label className="p-d-inline"><b>Họ tên : </b></label>
                                <p id="fullname" name="fullname" className="p-d-inline"> {dataViewInfo.fullname}</p>
                            </div>
                            <div className="p-col">
                                <label className="p-d-inline"><b>Email : </b></label>
                                <p id="email" name="email" className="p-d-inline">{dataViewInfo.email} </p>
                            </div>
                            <div className="p-col">
                                <label className="p-d-inline"><b>Phone : </b></label>
                                <p id="email" name="email" className="p-d-inline">{dataViewInfo.phone}</p>
                            </div>
                            <div className="p-col">
                                <label className="p-d-inline "><b>Quyền : </b></label>
                                <span id="status" name="status"
                                    className=" p-tag p-tag-success">{dataViewInfo.role}</span>
                            </div>
                            <div className="p-col">
                                <label className="p-d-inline "><b>Trạng thái : </b></label>
                                <span id="status" name="status" className=" p-tag p-tag-success">Hoạt động</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" p-col-12 p-md-6">
                    <div className="card h--100">
                        <div>
                            <h5>Lịch sử hoạt động</h5>
                        </div>
                        {dataViewHistory.map((detail, index) => {
                            return (
                                <div className="p-datatable" key={index}>
                                    <div className="p-col p-datatable-header">
                                        <label>[{first + index}] </label>
                                        <label className="p-d-inline "><b>{dataViewInfo.fullname}</b> vào</label>
                                        <p className="p-d-inline"> {detail.category_name} [{detail.action_name}] </p>
                                        <br />
                                        <span id="status" name="status"
                                            className=" p-tag ">{Moment(detail.created_at).format("DD-MM-YYYY HH:mm:ss")}</span>
                                    </div>
                                </div>)
                        })}
                        <br />
                        <Paginator first={first} rows={10} totalRecords={totalRecord}
                            onPageChange={(event) => onPageChange(event)}
                            pageLinkSize={pageLimit}
                        />
                    </div>
                </div>
                <DialogPro
                    visible={visibleDialog}
                    onHideDialog={onHideDialog}
                    dataViewInfo={dataViewInfo}
                    fetData={fetData}
                />
            </div>
        </div>
    )
}

export default withRouter(Profile);
