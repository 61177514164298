import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import store from "app/store";
import { Provider } from "react-redux";
import 'react-app-polyfill/ie9';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);
reportWebVitals();


