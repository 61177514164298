import * as type from '../constantsStore';

const initalState = {
    isAuth: false,
    userData: null,
    configData: {
        max_file_upload:null
    }
};

const authReducer = (state = initalState, action) => {
    switch (action.type) {
        case type.SET_AUTH_DATA:
            return { ...state, userData: action.payload, isAuth: true };
        case type.UNSET_AUTH_DATA:
            return { initalState };
        default:
            return state;
    }
};
export default authReducer;
