import AppFooter from './AppFooter';
import AppRouter from 'routers/AppRouter';
import AppTopbar from './AppTopbar';
import { AppProvider } from 'context/AppProvider';
import Login from 'pages/login/Login';
import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { HashRouter, Route, Switch } from 'react-router-dom';
import { authenticationService } from 'services/AuthenticationService';
import UserService from 'services/UserServices';
import { setAuthData } from 'app/actions';
import PrivateRoute from 'routers/PrivateRoute';
import LoginRouter from 'routers/LoginRouter';

const MainPage = (props) => {
    const serviceUser = new UserService();

    const getUser = async () => {
        const currentUser = authenticationService.currentUserValue;
        if (!currentUser) {
            window.location.hash = "/login";
        } else {
            const userResult = await serviceUser.getUserInfo();
            if (userResult) {
                props.setAuthData(userResult);
            }
        }
    };

    useEffect(() => {
        getUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const DefaultContainer = () => (
        <React.Fragment>
            <AppTopbar />
            <div className="layout-content">
                <AppRouter />
                <AppFooter />
            </div>
        </React.Fragment>
    );
    const LoginContainer = () => (
        <LoginRouter path="/login" component={(props) => <Login {...props} />} />
    );
    return (
        <div className="layout-wrapper">
            <AppProvider>
                <React.Fragment>
                    <HashRouter>
                        <Switch>
                            <Route exact path="/login" component={LoginContainer} />
                            <PrivateRoute component={DefaultContainer} />
                        </Switch>
                    </HashRouter>
                </React.Fragment>
            </AppProvider>
        </div>
    )
}
function mapStateToProps(state) {
    return { userData: state.authReducer };
}

const mapDispatchToProps = {
    setAuthData,
}

export default connect(mapStateToProps, mapDispatchToProps)(MainPage);
