const permissions = {
    ROLE_LIST: "role-list",
    ROLE_UPDATE: "role-update",
    USER_LIST: "user-list",
    USER_UPDATE: "user-update",
    PLACE_LIST: "place-list",
    PLACE_UPDATE: "place-update",
    DEVICE_LIST: "device-list",
    DEVICE_UPDATE: "device-update",
    MEETINGROOM_LIST: "meetingroom-list",
    MEETINGROOM_UPDATE: "meetingroom-update",
    SCHEDULE_LIST: "schedule-list",
    SCHEDULE_UPDATE: "schedule-update",
    SCHEDULELIST_PERSONAL: "schedule-list-personal",
    SCHEDULELIST_PERSONAL_UPDATE: "schedule-list-personal-update",
    CONFIG_VIEW: 'config-view',
    CONFIG_UPDATE: 'config-update',
    MAIL_LIST: 'mail-list',
    MAIL_UPDATE: 'mail-update',
};
export default permissions;
