
import React from 'react';
import { AppProvider } from 'context/AppProvider';
import MainPage from 'components/App/MainPage';

import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'assets/style/custom/custom.scss';

const App = (props) => {
    return (
        <div className="App">
            <AppProvider>
                <MainPage>
                </MainPage>
            </AppProvider>
        </div>

    );
};
export default (App);
