import { jsPDF } from "jspdf";

 function getUrlFilePreview(file) {
    let doc = new jsPDF();
    var blobPDF = new Blob([doc.output('blob'), file], { type: 'application/pdf' });
    var blobUrl = URL.createObjectURL(blobPDF);  //<--- THE ERROR APPEARS HERE

    return blobUrl;
}
export default getUrlFilePreview;
