import FetchAPI from "../helpers/FetchAPI";
import { TypeMethodConstantEnum } from "../helpers/MethodAPIConstant";
class MailTemplateServices extends FetchAPI {
    public async getAll(data) {
        let url = `mail-template/getAll`;
        return await this.request(url, { data }, TypeMethodConstantEnum.GET);
    }
    public async getFilter(data) {
        let url = `mail-template/index`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }
    public async getById(data) {
        let url = `/mail-template/getById`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }
    public async create(data) {
        let url = `mail-template/create`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }
    public async update(data) {
        let url = `mail-template/update`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }
    public async delete(data) {
        let url = `mail-template/delete`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }
    public async deleteMultiple(data) {
        let url = `mail-template/deleteMultiple`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }
}
export default MailTemplateServices;
