import { useIsMountedRef } from 'helpers/customHook/useIsMountedRef';
import React, { useEffect, useState } from 'react';
import { useGoogleLogout } from 'react-google-login';
import { useSelector } from "react-redux";
import { MenubarDemo } from 'components/menubar/MenubarDemo';
import clientId from 'helpers/constant/clientId';
import { useApp } from 'context/AppContext';
import { authenticationService } from 'services/AuthenticationService';
import store from 'app/store';
import { unsetAuthData } from 'app/actions';

const AppTopbar = () => {
    const [visibleRight, setVisibleRight] = useState(false);
    const { setStateSchedule } = useApp();
    const NAV_LINK_CONSTANT = [
        {
            label: "Quản lý đặt phòng",
            icon: "pi pi-fw pi-calendar-plus",
            items: [
                {
                    label: "Đặt lịch",
                    icon: "pi pi-fw pi-calendar-minus",
                    url: `#lich-dat-cua-toi/tao-moi`,
                    permission: "schedule-list-personal",
                    command: (e) => {
                        setStateSchedule(true);
                        setVisibleRight(false);
                    }
                },
                {
                    label: "Lịch đặt chung",
                    icon: "pi pi-fw pi-calendar",
                    url: "#lich-dat",
                    permission: "schedule-list",
                    command: (e) => {
                        setVisibleRight(false);
                    }
                },
                {
                    label: "Lịch đặt của tôi",
                    icon: "pi pi-fw pi-bookmark",
                    url: "#lich-dat-cua-toi",
                    permission: "schedule-list-personal",
                    command: (e) => {
                        setVisibleRight(false);
                    }
                }
            ]
        },
        {
            label: "Quản lý danh mục",
            icon: "pi pi-fw pi-folder",
            items: [
                {
                    label: "Quản lý địa điểm",
                    icon: "pi pi-fw pi-directions",
                    url: "#dia-diem",
                    permission: "place-list",
                    command: (e) => {
                        setVisibleRight(false);
                    }
                },
                {
                    label: "Quản lý thiết bị",
                    icon: "pi pi-fw pi-desktop",
                    url: "#thiet-bi",
                    permission: "device-list",
                    command: (e) => {
                        setVisibleRight(false);
                    }
                },
                {
                    label: "Quản lý phòng họp",
                    icon: "pi pi-fw pi-home",
                    url: "#phong-hop",
                    permission: "meetingroom-list",
                    command: (e) => {
                        setVisibleRight(false);
                    }
                },
                {
                    label: "Quản lý mẫu mail",
                    icon: "pi pi-fw pi-envelope",
                    url: "#mau-mail",
                    permission: "mail-list",
                    command: (e) => {
                        setVisibleRight(false);
                    }
                }
            ]
        },
        {
            label: "Hệ thống",
            icon: "pi pi-fw pi-th-large",
            items: [
                {
                    label: "Quản lý người dùng",
                    icon: "pi pi-fw pi-user",
                    url: "#nguoi-dung",
                    permission: "user-list",
                    command: (e) => {
                        setVisibleRight(false);
                    }
                },
                {
                    label: "Quản lý vai trò",
                    icon: "pi pi-fw pi-users",
                    url: "#vai-tro",
                    permission: "role-list",
                    command: (e) => {
                        setVisibleRight(false);
                    }
                },
                {
                    label: "Thiết lập chung",
                    icon: "pi pi-fw pi-cog",
                    url: "#cau-hinh",
                    permission: "config-view",
                    command: (e) => {
                        setVisibleRight(false);
                    }
                }
            ]
        },
    ];
    const userPer = useSelector(state => state.authReducer.userData);
    const [menu, setMenu] = useState([]);
    const onLogoutSuccess = (res) => {
        if (authenticationService.currentUserValue) {
            authenticationService.logout();
            store.dispatch(unsetAuthData());
        }
        window.location.hash = '/login';
        return true;
    };

    const onFailure = () => {
        window.location.hash = '/login';
        console.log('Đăng xuất thất bại');
    };

    const { signOut } = useGoogleLogout({
        clientId,
        onLogoutSuccess,
        onFailure,
    });
    const menuEnd = {
        label: userPer ? userPer.data.fullname : "Admin",
        icon: "pi pi-fw pi-user",
        className: "p-ml-auto",
        items: [
            {
                label: " Quản lý tài khoản",
                icon: "pi pi-fw pi-user-edit",
                url: "#ho-so",
                command: () => {
                    setVisibleRight(false);
                }
            },
            {
                label: "Thay đổi mật khẩu",
                icon: "pi pi-fw pi-unlock",
                url: "#doi-mat-khau",
                command: () => {
                    setVisibleRight(false);
                }
            },
            {
                label: "Đăng xuất",
                icon: "pi pi-fw pi-power-off",
                command: () => {
                    signOut();
                }
            }
        ]
    };
    const isMountedRef = useIsMountedRef();

    const getMenu = () => {
        if (isMountedRef) {
            let listMenuAfterPermissionCheck = [];
            NAV_LINK_CONSTANT.map((cate) => {
                if (userPer) {
                    cate.items = cate.items.filter((item) => {
                        // item.command = () => {
                        //     // setVisibleRight(false);
                        // }
                        return userPer.permission.includes(item.permission);
                    });
                    if (cate.items.length !== 0) {
                        listMenuAfterPermissionCheck.push(cate);
                    }
                }
                return listMenuAfterPermissionCheck;
            });
            listMenuAfterPermissionCheck.push(menuEnd);
            setMenu(listMenuAfterPermissionCheck);
        }
    };
    useEffect(() => {
        getMenu();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <React.Fragment>
            <div className="topbar">
                <MenubarDemo menu={menu}
                    visibleRight={visibleRight}
                    setVisibleRight={setVisibleRight}
                />
            </div>
        </React.Fragment>
    );
};

export default AppTopbar;

