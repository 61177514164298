import allLocales from "@fullcalendar/core/locales-all";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import FullCalendar from '@fullcalendar/react';
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { Tooltip } from "primereact/tooltip";
import { useApp } from "context/AppContext";
import typeRepeat from "helpers/constant/typeRepeat";
import getTimePlace from "helpers/getTimePlace";
import moment from "moment";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import ConfigServices from "services/ConfigServices";
import MyBookServices from "services/MyBookServices";
import RoomMeetingServices from "services/RoomMeetingServices";
import UserServices from "services/UserServices";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { DialogScheduleAll } from "./DialogScheduleAll";
import ListMeetingRoom from "./ListMeetingRoom";
import notification from "helpers/constant/notification";

export const ScheduleAll = (props) => {
    const { showAlert } = useApp();
    const serviceConfig = new ConfigServices();
    const serviceRoomMeetting = new RoomMeetingServices();
    const serviceUser = new UserServices();
    const serviceBook = new MyBookServices();
    //tìm kiếm
    const emptyData = {
        title: "",
        room_id: null,
        start_time: null,
        end_time: null,
        description: "",
        repeat: false,
        type_repeat: null,
        end_date_repeat: null,
        request_device: "",
        user_id: null,
        date: null,
        user_list: [],
        user_list_other: [],
        status: 1,
        file_attach: [],
        typeView: 1,
        typeUpdate: 1
    };
    const [dataSearch, setDataSearch] = useState({
        user_id: null,
        room_id: null,
        month: null,
        year: null,
        type_filter: null,
    });

    // danh sách phòng
    const [listMeeting, setListMeeting] = useState([]);
    //danh sách lịch đặt
    const [listBook, setListBook] = useState([]);
    // danh sách người dùng
    const [listUser, setListUser] = useState([]);
    //lấy danh sách config
    const [dataConfig, setDataConfig] = useState();
    // data thêm mới,  xóa
    const [adData, setAdData] = useState(emptyData);
    // data sửa,  xóa
    const [edData, setEdData] = useState(emptyData);
    // const [editData, setEditData] = useState(emptyData);
    const [time, setTime] = useState([]);
    // loại thêm mới hay sửa
    const [typeAd, setTypeAd] = useState(1);
    // data lỗi validate
    const [errData, setErrData] = useState({});
    const [hideRepeat, setHideRepeat] = useState(1);
    // data phân trang
    let filter = props.history.location.search;
    // loading datatable
    const [files, setFiles] = useState([]);

    const [typeUpdate, setTypeUpdate] = useState(null);
    const [visibleDialog, setVisibleDialog] = useState(false);
    const defaultDate = moment().format("YYYY-MM-DD");

    const eventRenderContent = (event) => {
        try {
            let data = event.event._def.extendedProps.data;
            return (
                <div className=" w--100 fc-event"
                    style={{
                        'borderColor': `${event.borderColor}`,
                        'backgroundColor': `${event.backgroundColor}`,
                        'color': '#fff'
                    }}
                    title={event.event.title}
                >
                    <div className="fc-content">
                        <b>
                            {data.start_time.slice(0, 5)}
                        </b>
                        -
                        <b>
                            {data.end_time.slice(0, 5)}
                        </b>
                        <span className="fc-title">
                            {" "}{event.event.title}
                        </span>
                    </div>
                </div>
            )
        } catch (error) {
            console.error('error: ', error);
        }
    }


    const getListConfig = async () => {
        const response = await serviceConfig.getAll();
        if (response) {
            setDataConfig(response.data);
            const timeInfo = getTimePlace(time, response.data);
            setTime(timeInfo);
        }
    };
    const getMeeting = async () => {
        const response = await serviceRoomMeetting.getAll();
        if (response) {
            const { data } = response;
            let arr = [];
            data.map((e) => arr.push({ code: e.id, name: e.name, color: e.color }));
            setListMeeting(arr);
        }
    };
    const getListUser = async () => {
        const response = await serviceUser.getAll();
        if (response) {
            const { data } = response;
            let arr = [];
            data.map((e) => arr.push({ code: e.id, name: e.email }));
            setListUser(arr);
        }
    };
    const fetData = async () => {
        try {
            const query = queryString.parse(props.location.search);
            const response = await serviceBook.getFilter({
                ...dataSearch,
                ...query,
            });
            if (response) {
                const { data, status, message } = response;
                if (status === 2) {
                    showAlert('error', notification.FAIL, message);
                    return
                }
                if (status === 1) {
                    let arr = [];
                    data.map((e) =>
                        arr.push({
                            groupId: e.id,
                            title: e.title,
                            start: e.date + "T" + e.start_time + "+07:00",
                            end: e.date + "T" + e.end_time + "+07:00",
                            color: "#" + e.room.color,
                            data: e,
                            description: e.title,
                        })
                    );
                    setListBook(arr);
                }
            }
        } catch (error) {
            console.log('error: ', error);
        }
    };
    useEffect(() => {
        getMeeting();
        getListUser();
        getListConfig();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        fetData();
    }, [filter]);// eslint-disable-line react-hooks/exhaustive-deps
    // tìm kiếm
    const onSearchClick = (e) => {
        e.preventDefault();
        let jsonDataSearch = queryString.parse(props.history.location.search);
        let pramSearch = {
            ...jsonDataSearch,
            user_id: dataSearch.user_id,
            room_id: dataSearch.room_id,
            month: dataSearch.month,
            year: dataSearch.year,
            type_filter: dataSearch.type_filter
        };
        let query = queryString.stringify(pramSearch);
        props.history.push({
            search: query,
        });
    };
    //update data search
    const updateField = (key, field) => {
        setDataSearch({
            ...dataSearch,
            [field]: key,
        });
    };
    // f5 lại trang xóa dữ liệu tìm kiếm trên URL
    window.onload = function () {
        props.history.push({
            search: "",
        });
    };
    const onHideDialog = () => {
        setAdData(emptyData);
        // setEdData(emptyData);
        setErrData({});
        setVisibleDialog(false);
    };
    const onCreateClick = (e) => {
        let dateCr;
        if (e === 1) {
            dateCr = new Date();
        } else {
            dateCr = e.date;
        }
        setAdData({
            ...adData,
            date: dateCr,
        });
        setTypeAd(1);
        setHideRepeat(1);
        setVisibleDialog(true);
        setFiles([]);
    };
    const eventClick = (e) => {
        onEditClick(e);
    }
    const onEditClick = async (e) => {
        let editData = e.event._def.extendedProps.data;
        let newEditData = { ...editData };
        let room_id = { code: editData.room.id, name: editData.room.name, color: editData.room.color };
        let start_time = {
            code: editData.start_time.slice(0, 5),
            name: editData.start_time.slice(0, 5),
        };
        let end_time = {
            code: editData.end_time.slice(0, 5),
            name: editData.end_time.slice(0, 5),
        };
        let repeat = false;
        let type_repeat = null;
        if (editData.repeat) {
            if (editData.repeat === 2) {
                repeat = true;
            } else {
                repeat = false;
            }
            if (editData.type_repeat) {
                if (editData.type_repeat === 1) {
                    type_repeat = typeRepeat[0];
                }
                if (editData.type_repeat === 1) {
                    type_repeat = typeRepeat[1];
                } else {
                    type_repeat = typeRepeat[2];
                }
            }
        }
        let end_date_repeat = null;
        if (editData.end_date_repeat) {
            end_date_repeat = new Date(editData.end_date_repeat);
        }
        let user_id = { code: editData.user.id, name: editData.user.email };
        let date = new Date(editData.date);
        let user_list_other;
        if (editData.user_list_other === null) {
            user_list_other = [];
        } else {
            user_list_other = JSON.parse(editData.user_list_other);
        }
        let file_attach = [];
        if (editData.file_attach) {
            file_attach = JSON.parse(editData.file_attach);
            setFiles(file_attach);
        }
        newEditData = {
            ...editData,
            room_id,
            start_time,
            end_time,
            repeat,
            type_repeat,
            end_date_repeat,
            user_id,
            date,
            user_list_other,
            file_attach
        };
        setEdData(newEditData);
        setTypeUpdate(editData.repeat_by);
        if (editData.repeat_by) {
            setHideRepeat(1);
        } else {
            setHideRepeat(2);
        }
        setTypeAd(2);
        setVisibleDialog(true);
    };
    const listFilter = [
        { name: "Lịch họp tôi tạo", code: 1 },
        { name: "Lịch họp tôi tham gia", code: 2 }
    ];

    const dataClick = (event) => {
        let dateCr = moment(new Date()).format("YYYY-MM-DD");
        if (event.dateStr >= dateCr) {
            onCreateClick(event);
        }
    }
    // tìm kiếm
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="p-flex-wrap w--100">

                    <Dropdown
                        id="user_id"
                        value={dataSearch.user_id}
                        options={listUser}
                        onChange={(e) => updateField(e.target.value, "user_id")}
                        name="user_id"
                        optionLabel="name"
                        optionValue="code"
                        filter
                        showClear
                        filterBy="name"
                        placeholder="Tìm theo người đặt"
                        className="p-mr-2 p-mb-2  w-xs-100 w-md-auto"
                    />
                    <Dropdown
                        id="room_id"
                        value={dataSearch.room_id}
                        options={listMeeting}
                        onChange={(e) => updateField(e.target.value, "room_id")}
                        name="room_id"
                        optionLabel="name"
                        optionValue="code"
                        filter
                        showClear
                        filterBy="name"
                        placeholder="Tìm theo phòng"
                        className="p-mr-2 p-mb-2  w-xs-100 w-md-auto"
                    />
                    <InputNumber
                        value={dataSearch.month}
                        onChange={(e) => updateField(e.value, "month")}
                        placeholder={"Tìm theo tháng"}
                        style={{ width: "200%" }}
                        max={12}
                        className="p-mr-2 p-mb-2  w-xs-100 w-md-auto"
                    />
                    <InputNumber
                        value={dataSearch.year}
                        onChange={(e) => updateField(e.value, "year")}
                        placeholder={"Tìm theo năm"}
                        style={{ width: "200%" }}
                        mode="decimal"
                        useGrouping={false}
                        className="p-mr-2 p-mb-2  w-xs-100 w-md-auto"
                        optionValue="code"
                    />
                    <Dropdown
                        id="type_filter"
                        value={dataSearch.type_filter}
                        options={listFilter}
                        onChange={(e) => updateField(e.target.value, "type_filter")}
                        name="type_filter"
                        optionLabel="name"
                        filter
                        showClear
                        filterBy="name"
                        placeholder="Chọn loại lịch họp"
                        className="p-mb-2  w-xs-100 w-md-auto"
                        optionValue="code"
                    />
                </div>
            </React.Fragment>
        );
    };
    const rightToolbarTemplateSearch = () => {
        return (
            <React.Fragment>
                <div className="p-flex-wrap w--100">
                    <Button
                        label="Tìm kiếm"
                        icon="pi pi-search"
                        type="submit"
                        className="p-button-primary p-mr-2 p-mb-2  w-xs-100 w-md-auto"
                        onClick={(e) => onSearchClick(e)}
                    />
                    <Button label="Thêm mới" icon="pi pi-plus"
                        className="p-button-success p-mb-2  w-xs-100 w-md-auto" onClick={() => onCreateClick(1)} />
                </div>
            </React.Fragment>
        );
    };


    return (
        <div className="schedule-all-css schedule">
            <div className="content-section implementation datatable-doc-demo">
                <div className="card">
                    <div>
                        <div className="card-header">
                            <h5>LỊCH ĐẶT CHUNG</h5>
                        </div>
                        <form onSubmit={(e) => onSearchClick(e)}>
                            <Toolbar
                                className="p-mb-4 p-d-flex p-flex-wrap"
                                left={leftToolbarTemplate}
                                right={rightToolbarTemplateSearch}
                            ></Toolbar>
                        </form>
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-9">
                                <FullCalendar events={listBook}
                                    initialDate={defaultDate}
                                    initialView='dayGridMonth'
                                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                    headerToolbar={{ left: 'prev,next today', center: 'title', right: 'dayGridMonth,timeGridWeek,timeGridDay' }}
                                    editable selectable selectMirror dayMaxEvents
                                    timeZone='local'
                                    locales={allLocales}
                                    locale="vi"
                                    dateClick={dataClick}
                                    eventClick={eventClick}
                                    eventContent={eventRenderContent}
                                    eventTimeFormat={{ hour: 'numeric', minute: '2-digit' }}
                                />
                            </div>
                            <Tooltip target=".fc-allow-mouse-resize" mouseTrack mouseTrackLeft={10} />
                            <div className="p-col-12 p-lg-3">
                                <ListMeetingRoom listMeeting={listMeeting}
                                // style={{ width: '25vw' }}
                                />
                            </div>
                        </div>
                        <DialogScheduleAll
                            visible={visibleDialog}
                            time={time}
                            onHideDialog={onHideDialog}
                            adData={adData}
                            setAdData={setAdData}
                            typeAd={typeAd}
                            typeUpdate={typeUpdate}
                            errData={errData}
                            setErrData={setErrData}
                            listMeeting={listMeeting}
                            listUser={listUser}
                            showAlert={showAlert}
                            fetData={fetData}
                            edData={edData}
                            hideRepeat={hideRepeat}
                            dataConfig={dataConfig}
                            files={files} setFiles={setFiles}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default withRouter(ScheduleAll);
