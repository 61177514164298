import { useApp } from "context/AppContext";
import checkPermission from "helpers/checkPermisson";
import pages from "helpers/constant/pages";
import permissions from "helpers/constant/permissions";
import titleDefault from "helpers/constant/titleDefault";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Paginator } from "primereact/paginator";
import { Toolbar } from "primereact/toolbar";

import Service from "services/UserServices";
import { DialogUser } from "./DialogUser";
import queryString from 'query-string';
import notification from "helpers/constant/notification";


export const Users = (props) => {
    const { showAlert, pageLimit } = useApp();
    const services = new Service();
    const [data, setData] = useState();
    const [first, setFirst] = useState(0);
    const [dataSearch, setDataSearch] = useState({
        text_search: "",
    });
    const [row, setRow] = useState(10);
    const [visibleDialog, setVisibleDialog] = useState(false);
    const [idDelete, setIdDelete] = useState();
    const [idReset, setIdReset] = useState({
        id: "",
    });
    // check quyền
    const [typeRole, setTypeRole] = useState(1);
    const [idDeleteMultiple, setIdDeleteMultiple] = useState({
        id: [],
    });
    const [typeAd, setTypeAd] = useState(1);

    const [dataEdit, setDataEdit] = useState(null);
    const [pagination, setPagination] = useState({
        from: 0,
        to: 0,
        row: 0,
        total: 0,
        page: 0,
    });
    const [deleteUserDialog, setDeleteUserDialog] = useState(false);
    const [resetPasswordDialog, setResetPasswordDialog] = useState(false);
    const [typeDelete, setTypeDelete] = useState(null);
    const [selectedUser, setSelectedUser] = useState([]);

    const fetData = async () => {
        const query = queryString.parse(props.location.search);
        let result = await services.getListUser({
            ...dataSearch,
            ...query,
        });
        if (result) {
            const { data, status, message } = result;
            if (status === 2) {
                showAlert('error', notification.FAIL, message);
                return;
            }
            setData(data.data);
            setPagination({
                from: data.from,
                to: data.to,
                row: data.per_page,
                total: data.total,
                page: data.current_page,
            })
        }
    };
    useEffect(() => {
        fetData();
        // eslint-disable-next-line
    }, [props.history.location.search]);
    useEffect(() => {
        let typePer = checkPermission(permissions.USER_UPDATE);
        setTypeRole(typePer);
        // eslint-disable-next-line
    }, [])

    const onEditUser = (rowData) => {
        setDataEdit(rowData);
        setTypeAd(2);
        setVisibleDialog(true);
    };



    const onDeleteUser = (data) => {
        setDeleteUserDialog(true);
        setIdDelete(data);
        setTypeDelete(1);
    };
    const onResetPasswordUser = (data) => {
        setResetPasswordDialog(true);
        setIdReset({ id: data });
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="p-flex-wrap w--100">
                    <InputText
                        value={dataSearch.text_search}
                        onChange={(e) => handleChangeDataSearch(e)}
                        placeholder={"Mã, tên hoặc email"}
                        className=" w-xs-100 w-md-auto p-mb-2"
                        name="text_search"
                    />
                </div>
            </React.Fragment>
        );
    };

    const onSearchClick = (e) => {
        e.preventDefault();
        let jsonDataSearch = queryString.parse(props.history.location.search);
        let pramSearch = {
            ...jsonDataSearch,
            text_search: dataSearch.text_search,
        };
        let query = queryString.stringify(pramSearch);
        props.history.push({
            search: query,
        });
    };

    const onCreateClick = () => {
        setTypeAd(1);
        setVisibleDialog(true);
    };

    const onPageChange = (event) => {
        setFirst(event.first);
        setRow(event.rows);
        let jsonDataSearch = queryString.parse(props.history.location.search);
        let dataSearch = {
            ...jsonDataSearch,
            row: event.rows,
            page: (event.page + 1)
        }
        let query = queryString.stringify(dataSearch);
        props.history.push({
            search: query,
        });
    };

    const handleChangeDataSearch = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setDataSearch({ ...dataSearch, [name]: value });
    };

    const onHideDialog = () => {
        setDataEdit(null);
        setVisibleDialog(false);
    };

    const hideDeleteUserDialog = () => {
        setDeleteUserDialog(false);
        setSelectedUser([]);
    };

    const hideResetPasswordDialog = () => {
        setResetPasswordDialog(false);
    };

    const deleteUser = async (type) => {
        if (type === 1) {
            const result = await services.onDeleteUser(idDelete);
            if (result && result.status === 1) {
                showAlert("success", "Xóa thành công", result.message);
                hideDeleteUserDialog();
                await fetData();
            } else if (result && result.status === 2) {
                showAlert("error", "Xóa thất bại", result.message);
                hideDeleteUserDialog();
            }
        } else {
            const result = await services.onDeleteMultiple(idDeleteMultiple);
            if (result && result.status === 1) {
                showAlert("success", "Xóa thành công", result.message);
                hideDeleteUserDialog();
                await fetData();
            } else if (result && result.status === 2) {
                showAlert("error", "Xóa thất bại", result.message);
                hideDeleteUserDialog();
            }
        }
    };
    const resetPass = async () => {
        const result = await services.onResetPassword(idReset);
        if (result && result.status === 1) {
            showAlert("success", "Cập nhật thành công", result.message);
            hideResetPasswordDialog();
            await fetData();
        } else if (result && result.status === 2) {
            showAlert("error", "Cập nhật thất bại", result.message);
            hideResetPasswordDialog();
        }
    };

    const onDeleteMultiple = async () => {
        setDeleteUserDialog(true);
        setTypeDelete(2);
        let listIdUser = [];
        selectedUser.forEach((answer) => {
            listIdUser.push(answer.id);
        });
        setIdDeleteMultiple({ id: listIdUser });
    };


    const rightToolbarTemplate = () => {
        const classSearch = typeRole === 1 ? "p-mr-2" : "";
        return (
            <React.Fragment>
                <div className="p-flex-wrap w--100">
                    <Button
                        label="Tìm kiếm"
                        icon="pi pi-search"
                        type="submit"
                        className={`p-button-primary w-xs-100 w-md-auto p-mb-2 ${classSearch}`}
                        onClick={(e) => onSearchClick(e)}
                    />
                    {typeRole === 1 &&
                        <Button
                            label="Xóa"
                            icon="pi pi-trash"
                            className="p-button-danger p-mr-2 p-mb-2  w-xs-100 w-md-auto"
                            onClick={onDeleteMultiple}
                            disabled={selectedUser.length === 0 ? true : false}
                        />
                    }
                    {typeRole === 1 &&
                        <Button
                            label="Thêm mới"
                            icon="pi pi-plus"
                            className="p-button-success p-mb-2  w-xs-100 w-md-auto"
                            onClick={onCreateClick}
                        />
                    }
                </div>

            </React.Fragment>
        );
    };
    const deleteUserDialogFooter = (
        <React.Fragment>
            <Button
                label="Hủy"
                icon="pi pi-times"
                className="p-button-text p-button-raised"
                onClick={hideDeleteUserDialog}
            />
            <Button
                label="Xóa"
                icon="pi pi-check"
                className="p-button-danger p-button-raised"
                onClick={() => deleteUser(typeDelete)}
            />
        </React.Fragment>
    );

    const resetPasswordDialogFooter = (
        <React.Fragment>
            <Button
                label="Hủy"
                icon="pi pi-times"
                className="p-button-text p-button-raised"
                onClick={hideResetPasswordDialog}
            />
            <Button
                label="Xác nhận"
                icon="pi pi-check"
                className="p-button-primary p-button-raised"
                onClick={() => resetPass()}
            />
        </React.Fragment>
    );
    const checkBoxBodyTemplate = (rowData, column) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Chọn</span>
            </React.Fragment>
        );
    };
    const renderRowIndex = (rowData, column) => {
        return (
            <React.Fragment>
                <span className="p-column-title">#</span>
                {column.rowIndex + 1 + first}
            </React.Fragment>
        );
    };
    const codeBodyTemplate = (rowData, column) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Mã</span>
                {rowData.code}
            </React.Fragment>
        );
    };
    const emailBodyTemplate = (rowData, column) => {
        return (
            <React.Fragment>
                <div className="p-d-flex">
                    <span className="p-column-title">Email</span>
                    <span className="max-line-3">{rowData.email}</span>
                </div>
            </React.Fragment>
        );
    };
    const fullnameBodyTemplate = (rowData, column) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Họ tên</span>
                {rowData.fullname}
            </React.Fragment>
        );
    };
    const phoneBodyTemplate = (rowData, column) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Số điện thoại</span>
                {rowData.phone}
            </React.Fragment>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>

                <i
                    className="pi pi-pencil p-mr-2 icon-medium pointer text-success"
                    title="Cập nhật"
                    onClick={() => onEditUser(rowData)}
                ></i>
                <i
                    className="pi pi-replay icon-medium p-mr-2 text-primary pointer"
                    title="Reset password"
                    onClick={() => onResetPasswordUser(rowData)}
                ></i>
                <i
                    className="pi pi-trash icon-medium text-danger pointer"
                    title="Xóa người dùng"
                    onClick={() => onDeleteUser(rowData)}
                ></i>
            </React.Fragment>
        );
    };
    return (
        <div className="user-css">

            <div className="content-section implementation datatable-responsive-demo">
                <div className="card">
                    <div className="card-header">
                        <h5>QUẢN LÝ NGƯỜI DÙNG</h5>
                    </div>
                    <form onSubmit={(e) => onSearchClick(e)}>
                        <Toolbar
                            className="p-mb-4 p-d-flex p-flex-wrap"
                            left={leftToolbarTemplate}
                            right={rightToolbarTemplate}
                        ></Toolbar>
                    </form>
                    <DataTable
                        rowHover
                        value={data}
                        emptyMessage="Không có data"
                        className="p-datatable-gridlines table-word-break p-datatable-responsive-demo"
                        selection={selectedUser}
                        onSelectionChange={(e) => setSelectedUser(e.value)}
                    >
                        <Column selectionMode="multiple" className="stt-table checkbox-table"
                            body={checkBoxBodyTemplate}
                        />
                        <Column
                            body={renderRowIndex}
                            header="#"
                            className="stt-table"
                        />
                        <Column field="code" header="Mã" sortable body={codeBodyTemplate} />
                        <Column field="email" header="Email" sortable body={emailBodyTemplate} />
                        <Column field="fullname" header="Họ tên" sortable body={fullnameBodyTemplate} />
                        <Column field="phone" header="Số điện thoại" sortable body={phoneBodyTemplate} />
                        {typeRole === 1 && (
                            <Column
                                header="Tác vụ"
                                body={actionBodyTemplate}
                                className="p-text-center action-table"
                            />
                        )}
                    </DataTable>
                    <div className="p-d-flex p-flex-wrap">
                        <div className="text-normal p-mt-2">
                            <span>Hiển thị từ <b>{pagination.from}</b> đến <b>{pagination.to}</b> / Tổng <b>{pagination.total}</b> bản ghi</span>
                        </div>
                        <div className="p-ml-auto p-mt-2">
                            <Paginator first={first} rows={row} totalRecords={pagination.total}
                                rowsPerPageOptions={pages} onPageChange={(event) => onPageChange(event)}
                                template=" RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink  "
                                pageLinkSize={pageLimit}
                            />
                        </div>
                    </div>
                </div>
                <DialogUser
                    visible={visibleDialog}
                    onHideDialog={onHideDialog}
                    typeAd={typeAd}
                    feData={fetData}
                    dataEdit={dataEdit}
                />
                <Dialog
                    visible={deleteUserDialog}
                    className="p-dialog-default"
                    header={titleDefault.USER_DELETE}
                    typeDelete={typeDelete}
                    modal
                    footer={deleteUserDialogFooter}
                    onHide={hideDeleteUserDialog}
                >
                    <div className="confirmation-content">
                        <i
                            className="pi pi-exclamation-triangle p-mr-3"
                            style={{ fontSize: "2rem" }}
                        />
                        <span> Bạn có chắc chắn muốn xóa người dùng này không?</span>
                    </div>
                </Dialog>
                <Dialog
                    visible={resetPasswordDialog}
                    className="p-dialog-default"
                    header="Đặt lại mật khẩu mặc định"
                    typeDelete={typeDelete}
                    modal
                    footer={resetPasswordDialogFooter}
                    onHide={hideResetPasswordDialog}
                >
                    <div className="confirmation-content">
                        <i
                            className="pi pi-exclamation-triangle p-mr-3"
                            style={{ fontSize: "2rem" }}
                        />
                        <span> Bạn có chắc chắn muốn reset mật khẩu người dùng này không?</span>
                    </div>
                </Dialog>
            </div>
        </div>
    );
};
export default withRouter(Users);
