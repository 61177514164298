import { BehaviorSubject } from "rxjs";
import FetchAPI from "../helpers/FetchAPI";

const currentUserSubject = new BehaviorSubject(
    JSON.parse(localStorage.getItem("currentUser")!)
);

export const authenticationService = {
    login,
    logout,
    loginGG,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() {
        return currentUserSubject.value;
    },
};

async function login(data) {
    const fetchAPI = new FetchAPI({});

    const user = await fetchAPI.request(`auth/login`, data, "POST");
    if (user && user.token) {
        localStorage.setItem("currentUser", JSON.stringify(user));
        currentUserSubject.next(user);
        return user;
    } else if (user) {
        return user;
    }
    return null;
}
async function loginGG(data) {
    const fetchAPI = new FetchAPI({});

    const user = await fetchAPI.request(`auth/loginGG`, data, "POST");
    if (user && user.token) {
        localStorage.setItem("currentUser", JSON.stringify(user));
        currentUserSubject.next(user);
        return user;
    } else if (user) {
        return user;
    }
    return null;
}

function logout() {
    localStorage.clear();
    currentUserSubject.next(null);
}
