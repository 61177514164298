import { SelectedTemplate } from 'components/dropdow-value/SelectedTemplate';
import { OptionTemplate } from 'components/dropdow-value/OptionTemplate';
import { useApp } from "context/AppContext";
import notification from "helpers/constant/notification";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import Service from "services/UserServices";
import titleDefault from 'helpers/constant/titleDefault';

export const DialogUser = (props) => {
    const { showAlert } = useApp();
    const service = new Service();
    const emptyData = {
        email: "",
        password: "",
        confirm_password: "",
        phone: "",
        fullname: "",
        role: "",
        type: "",
    };
    const [adData, setAdData] = useState(emptyData);
    const [dataError, setDataError] = useState(emptyData);
    const [roleOption, setRoleOption] = useState();
    const typeUser = [
        { name: "Quản trị phần mềm", code: 1 },
        { name: "Quản trị phòng họp", code: 2 },
        { name: "Nhân viên ", code: 3 },
    ];
    const handleChangeInput = (e) => {
        setAdData({ ...adData, [e.target.name]: e.target.value });
    };
    const getDataEdit = () => {
        if (props.dataEdit) {
            typeUser.forEach((answer) => {
                if (answer.code === props.dataEdit.type) {
                    setAdData({
                        type: answer,
                        email: props.dataEdit.email,
                        phone: props.dataEdit.phone || "",
                        fullname: props.dataEdit.fullname,
                        role: {
                            name: props.dataEdit.user_role.role.name,
                            code: props.dataEdit.user_role.role_id,
                        },
                        code: props.dataEdit.code,
                        id: props.dataEdit.id,
                    });
                }
            });
        } else {
            clearData();
        }
    };
    const onCreateClick = async () => {
        const result = await service.onCreateUser(adData);
        try {
            const { error, message, status } = result;
            if (status === 2) {
                if (error) {
                    setDataError(error);
                    showAlert("error", notification.CREATE_FAIL, notification.MESSAGE_VALIDATE);
                } else {
                    showAlert("error", notification.CREATE_FAIL, message);
                }
                return;
            }
            if (status === 1) {
                showAlert("success", notification.CREATE_SUCCESS, message);
                clearData();
                props.onHideDialog();
                await props.feData();
            }
        } catch (error) {
            showAlert("error", notification.CREATE_FAIL, notification.MESSAGE_ERROR);
        }

    }

    const getRole = async () => {
        const result = await service.getListRole();
        if (result) {
            const { data, message, status } = result;
            if (status === 2) {
                showAlert("error", notification.CREATE_FAIL, message);
                return;
            }
            setRoleOption(data);
        }
    };

    const onEditClick = async () => {
        const result = await service.onEditUser(adData);
        try {
            const { error, message, status } = result;
            if (status === 2) {
                if (error) {
                    setDataError(error);
                    showAlert("error", notification.CREATE_FAIL, notification.MESSAGE_VALIDATE);
                } else {
                    showAlert("error", notification.CREATE_FAIL, message);
                }
                return;
            }
            if (status === 1) {
                showAlert("success", notification.UPDATE_SUCCESS, message);
                clearData();
                props.onHideDialog();
                await props.feData();
            }
        } catch (error) {
            showAlert("error", notification.UPDATE_FAIL, notification.MESSAGE_ERROR);
        }
    };


    const clearData = () => {
        setAdData(emptyData);
        setDataError(emptyData);
    };
    const onHide = () => {
        props.onHideDialog();
        clearData();
    };

    const productDialogFooter = (
        <React.Fragment>
            <Button
                label="Hủy"
                icon="pi pi-times"
                className="p-button-text p-button-raised"
                onClick={onHide}
            />
            <Button
                label="Lưu"
                icon="pi pi-check"
                className="p-button-raised"
                onClick={!props.dataEdit ? onCreateClick : onEditClick}
            />
        </React.Fragment>
    );
    useEffect(() => {
        getRole();
        getDataEdit();
        // eslint-disable-next-line
    }, [props.dataEdit]);
    return (
        <div>
            <Dialog
                visible={props.visible}
                header={props.typeAd ===1 ? titleDefault.USER_CREATE :titleDefault.USER_UPDATE}
                modal
                className="p-fluid p-dialog-default p-dialog-lg"
                footer={productDialogFooter}
                onHide={onHide}
            >
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-col-12 p-md-6 p-field">
                        <label htmlFor="fullname" className="label-bold">
                            Họ tên <font className="text-danger">*</font>
                        </label>
                        <InputText
                            id="fullname"
                            value={adData.fullname}
                            onChange={handleChangeInput}
                            autoFocus
                            name="fullname"
                            placeholder="Nhập họ tên"
                        />
                        <span className="p-invalid">
                            {dataError?.fullname}
                        </span>
                    </div>
                    <div className="p-col-12 p-md-6 p-field">
                        <label htmlFor="phone" className="label-bold">Số điện thoại</label>
                        <InputText
                            id="phone"
                            value={adData.phone}
                            onChange={handleChangeInput}
                            name="phone"
                            placeholder="Nhập số điện thoại"
                        />
                        <span className="p-invalid">
                            {dataError?.phone}
                        </span>
                    </div>
                    <div className="p-field p-col-12">
                        <label htmlFor="email" className="label-bold">
                            Email <font className="text-danger">*</font>
                        </label>
                        <InputText
                            id="email"
                            value={adData.email}
                            onChange={handleChangeInput}
                            name="email"
                            placeholder="Nhập email"
                        />
                        <span className="p-invalid">
                            {dataError?.email}
                        </span>
                    </div>
                    <div className="p-col-12 p-md-6 p-field">
                        <label htmlFor="type" className="label-bold">
                            Loại người dùng{" "}
                            <font className="text-danger">*</font>
                        </label>
                        <Dropdown
                            optionLabel="name"
                            value={adData.type}
                            options={typeUser}
                            onChange={handleChangeInput}
                            placeholder="Chọn loại người dùng"
                            name="type"
                            filter
                            valueTemplate={SelectedTemplate} itemTemplate={OptionTemplate}
                        />
                        <span className="p-invalid">
                            {dataError?.type}
                        </span>
                    </div>
                    <div className="p-col-12 p-md-6 p-field">
                        <label htmlFor="role" className="label-bold">
                            Vai trò <font className="text-danger">*</font>
                        </label>
                        <Dropdown
                            optionLabel="name"
                            value={adData.role}
                            options={roleOption}
                            onChange={handleChangeInput}
                            placeholder="Chọn loại vai trò"
                            name="role"
                            resetFilterOnHide
                            valueTemplate={SelectedTemplate} itemTemplate={OptionTemplate}
                        />
                        <span className="p-invalid">
                            {dataError?.role}
                        </span>
                    </div>
                </div>
                {props.dataEdit === null ? (
                    <div className="p-grid">
                        <div className="p-col-12 p-md-6 p-field">
                            <label htmlFor="password" className="label-bold">
                                Mật khẩu <font className="text-danger">*</font>
                            </label>
                            <Password
                                id="password"
                                onChange={handleChangeInput}
                                toggleMask
                                name="password"
                                autoComplete="off"
                                feedback={false}
                                placeholder="Nhập mật khẩu"
                            />
                            <span className="p-invalid">
                                {dataError?.password}
                            </span>
                        </div>
                        <div className="p-col-12 p-md-6 p-field">
                            <label htmlFor="confirm_password" className="label-bold">
                                Xác nhận mật khẩu{" "}
                                <font className="text-danger">*</font>
                            </label>
                            <Password
                                id="confirm_password"
                                onChange={handleChangeInput}
                                toggleMask
                                feedback={false}
                                name="confirm_password"
                                placeholder="Nhập lại mật khẩu"

                            />
                            <span className="p-invalid">
                                {dataError?.confirm_password}
                            </span>
                        </div>
                    </div>
                ) : (
                    <div></div>
                )}
            </Dialog>
        </div>
    );
};

DialogUser.propTypes = {
    visible: PropTypes.bool.isRequired,
    onHideDialog: PropTypes.func.isRequired,
    dataEdit: PropTypes.object,
    feData: PropTypes.func.isRequired,
};
