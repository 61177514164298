import { useApp } from 'context/AppContext';
import notification from 'helpers/constant/notification';
import React, { useState } from 'react';
import { withRouter } from "react-router-dom";
import { Button } from 'primereact/button';
import { authenticationService } from "services/AuthenticationService";
import Service from "services/UserServices";
import { Password } from 'primereact/password';
import { unsetAuthData } from 'app/actions';
import store from 'app/store';

export const ChangePassWord = (props) => {
    const { showAlert } = useApp();
    const service = new Service();
    const emptyData = {
        password: "",
        newPassword: "",
        reNewPassword: "",
    }
    const [dataSave, setDataSave] = useState(emptyData);
    const [dataError, setDataError] = useState(emptyData);
    const handleChangeInput = (e) => {
        setDataSave({ ...dataSave, [e.target.name]: e.target.value });
    }

    const Logout = () => {
        if (authenticationService.currentUserValue) {
            authenticationService.logout();
            store.dispatch(unsetAuthData());
            window.location.hash = "/login";
        }
    }

    const onSubmit = async () => {
        try {
            const result = await service.onChangePassword(dataSave);
            const { errors, message, status } = result;
            if (status === 2) {
                if (errors) {
                    setDataError(errors)
                } else {
                    showAlert('error', notification.UPDATE_FAIL, notification.MESSAGE_VALIDATE);
                }
                return;
            }

            if (status === 1) {
                showAlert('success', 'Đổi mật khẩu thành công', message);
                setTimeout(function () {
                    Logout();
                }, 1000);
            }
        } catch (error) {
            showAlert('error', notification.FAIL, notification.MESSAGE_ERROR);
        }
    }
    return (
        <div className="content-section implementation formlayout-demo">
            <div className="card">
                <div>
                    <h5 className="p-text-uppercase">Đổi mật khẩu</h5>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col p-col-12 p-md-4">
                            <label htmlFor="password" className="label-bold">Mật khẩu cũ <span className="text-danger">*</span></label>
                            <Password id="password" name="password" onChange={handleChangeInput}
                                placeholder="Nhập mật khẩu cũ"
                                className=""
                                feedback={false}
                                toggleMask
                            />
                            <span className="p-invalid">{dataError.password}</span>
                        </div>
                        <div className="p-field p-col p-col-12 p-md-4">
                            <label htmlFor="newPassword" className="label-bold">Mật khẩu mới <span className="text-danger">*</span></label>
                            <Password id="newPassword" name="newPassword" onChange={handleChangeInput}
                                placeholder="Nhập mật khẩu mới"
                                toggleMask
                                feedback={false}
                            />
                            <span className="p-invalid">{dataError.newPassword}</span>
                        </div>
                        <div className="p-field p-col p-col-12 p-md-4">
                            <label htmlFor="reNewPassword" className="label-bold">Nhập lại mật khẩu <span className="text-danger">*</span></label>
                            <Password id="reNewPassword" name="reNewPassword" onChange={handleChangeInput}
                                placeholder="Nhập lại mật khẩu mới"
                                toggleMask
                                feedback={false}
                            />
                            <span className="p-invalid">{dataError.reNewPassword}</span>
                        </div>
                    </div>
                    <div className="p-d-flex">
                        <div className="p-d-inline p-mr-2 p-mb-2 p-mx-auto">
                            <Button label="Cập nhật" icon="pi pi-check" className="p-button p-d-inline p-mr-2 p-mb-2"
                                onClick={onSubmit} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(ChangePassWord);

