const titleDefault = {
    DEVICE_CREAT: 'Tạo mới thiết bị',
    DEVICE_UPDATE: 'Cập nhật thiết bị',
    DEVICE_DELETE: 'Xóa thiết bị',

    PLACE_CREATE: 'Tạo mới địa điểm',
    PLACE_UPDATE: 'Cập nhật địa điểm',
    PLACE_DELETE: 'Xóa địa điểm',

    ROLE_CREATE: 'Tạo mới vai trò',
    ROLE_UPDATE: 'Cập nhật vai trò',
    ROLE_DELETE: 'Xóa vai trò',

    MAIL_TEMPLATE_CREATE: 'Tạo mới mẫu mail',
    MAIL_TEMPLATE_UPDATE: 'Cập nhật mẫu mail',
    MAIL_TEMPLATE_DELETE: 'Xóa mẫu mail',

    ROOM_MEETING_CREATE: 'Tạo mới phòng họp',
    ROOM_MEETING_UPDATE: 'Cập nhật phòng họp',
    ROOM_MEETING_DELETE: 'Xóa phòng họp',
    ROOM_MEETING_DETAIL: 'Xem chi tiết phòng họp',
    ROOM_MEETING_STATUS: 'Cập nhật trạng thái phòng họp',

    SCHEDULE_CREATE: 'Tạo mới lịch đặt',
    SCHEDULE_UPDATE: 'Cập nhật lịch đặt',
    SCHEDULE_DELETE: 'Xóa lịch đặt',

    MY_SCHEDULE_CREATE: 'Tạo mới lịch đặt cá nhân',
    MY_SCHEDULE_UPDATE: 'Cập nhật lịch đặt cá nhân',
    MY_SCHEDULE_DELETE: 'Xóa lịch đặt cá nhân',

    CONFIG_CREATE: 'Tạo mới cấu hình',
    CONFIG_UPDATE: 'Cập nhật cấu hình',
    CONFIG_DELETE: 'Xóa  cấu hình',

    USER_CREATE: 'Tạo mới người dùng',
    USER_UPDATE: 'Cập nhật người dùng',
    USER_DELETE: 'Xóa người dùng',

    _CREATE: 'Tạo mới ',
    _UPDATE: 'Cập nhật ',

};
export default titleDefault;
