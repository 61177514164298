import { Button } from 'primereact/button';
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog';
import { TabPanel, TabView } from 'primereact/tabview';
import React from 'react'
import PropTypes from 'prop-types'
import { ColorPicker } from 'primereact/colorpicker';
import titleDefault from 'helpers/constant/titleDefault';
import { BiBookmark, BiNote, BiUser } from 'react-icons/bi';
import { IoLocationSharp } from "react-icons/io5";
import { GrCapacity } from "react-icons/gr";
import { IoIosColorPalette } from "react-icons/io";
import {  MdDevicesOther } from "react-icons/md";
import { SiGoogleclassroom } from "react-icons/si";

const DetailRoomMeeting = props => {
    const { dialogDetail, setDialogDetail, activeIndex, setActiveIndex, detailData } = props;
    const onHideDialog = () => {
        setDialogDetail(false);
    }
    // index của table
    const renderRowIndex = (rowData, column) => {
        return (
            <React.Fragment>
                <span className="p-column-title">#</span>
                {column.rowIndex + 1}
            </React.Fragment>
        );
    }
    const deviceBodyTemplate = (rowData, column) => {
        return (
            <React.Fragment>
                <div className="p-d-flex">
                    <div className="p-column-title">Tên</div>
                    <div className="max-line-3" title={rowData.device.name}>
                        {rowData.device.name}
                    </div>
                </div>
            </React.Fragment>
        );
    };
    const noteBodyTemplate = (rowData, column) => {
        return (
            <React.Fragment>
                <div className="p-d-flex">
                    <div className="p-column-title">Ghi chú</div>
                    <div className="max-line-3" title={rowData.note}>
                        {rowData.note}
                    </div>
                </div>
            </React.Fragment>
        );
    };
    const amountBodyTemplate = (rowData, column) => {
        return (
            <React.Fragment>
                <div className="p-d-flex">
                    <div className="p-column-title">Số lượng</div>
                    <div >
                        {rowData.amount}
                    </div>
                </div>
            </React.Fragment>
        );
    };
    const dialogFooterPrevios = (
        <React.Fragment>
            <Button label="Hủy" icon="pi pi-times" className="p-button-text p-button-raised p-mr-2" onClick={onHideDialog} />
            <Button label="Tiếp" icon="pi pi-angle-right" className="p-button-raised" onClick={() => setActiveIndex(1)} />
        </React.Fragment>
    );
    const dialogFooterNext = (
        <React.Fragment>
            <Button label="Hủy" icon="pi pi-times" className="p-button-text p-button-raised p-mr-2" onClick={onHideDialog} />
            <Button label="Lùi" icon="pi pi-angle-left" className="p-button-raised p-mr-2 " onClick={() => setActiveIndex(0)} />
        </React.Fragment>
    );
    return (
        <div className="">
            <Dialog visible={dialogDetail}
                header={titleDefault.ROOM_MEETING_DETAIL}
                footer={activeIndex === 1 ? dialogFooterNext : dialogFooterPrevios}
                modal className="p-fluid p-dialog-default wr--50 DetailRoomMeeting"
                onHide={onHideDialog}>
                <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}
                className="tabview-custom">
                    <TabPanel header={<span><SiGoogleclassroom className="p-mr-2"/>Thông tin phòng họp</span>}>
                        <div className="content__detail p-d-flex p-flex-wrap p-col-12">
                            <div className="content__detail__wrap-title p-d-flex">
                                <div className="content__detail__icon p-mr-2"><BiBookmark /></div>
                                <div className="content__detail__title p-mr-2">Tên phòng:</div>
                            </div>
                            <div className="content__detail__value ">{detailData.name}</div>
                        </div>
                        <div className="content__detail p-d-flex p-flex-wrap p-col-12">
                            <div className="content__detail__wrap-title p-d-flex">
                                <div className="content__detail__icon p-mr-2"><IoLocationSharp /></div>
                                <div className="content__detail__title p-mr-2">Địa điểm:</div>
                            </div>
                            <div className="content__detail__value ">{detailData.place}</div>
                        </div>
                        <div className="content__detail p-d-flex p-flex-wrap p-col-12">
                            <div className="content__detail__wrap-title p-d-flex">
                                <div className="content__detail__icon p-mr-2"><GrCapacity /></div>
                                <div className="content__detail__title p-mr-2">Sức chứa:</div>
                            </div>
                            <div className="content__detail__value ">{detailData.capacity}</div>
                        </div>
                        <div className="content__detail p-d-flex p-flex-wrap p-col-12">
                            <div className="content__detail__wrap-title p-d-flex">
                                <div className="content__detail__icon p-mr-2"><BiUser /></div>
                                <div className="content__detail__title p-mr-2">Người phụ trách:</div>
                            </div>
                            <div className="content__detail__value ">{detailData.owner}</div>
                        </div>
                        <div className="content__detail p-d-flex p-flex-wrap p-col-12">
                            <div className="content__detail__wrap-title p-d-flex">
                                <div className="content__detail__icon p-mr-2"><IoIosColorPalette /></div>
                                <div className="content__detail__title p-mr-2">Màu phòng họp:</div>
                            </div>
                            <div className="content__detail__value w--20">
                                <ColorPicker value={detailData.color} />
                            </div>
                        </div>
                        <div className="content__detail p-d-flex p-flex-wrap p-col-12">
                            <div className="content__detail__wrap-title p-d-flex">
                                <div className="content__detail__icon p-mr-2"><BiNote /></div>
                                <div className="content__detail__title p-mr-2">Ghi chú:</div>
                            </div>
                            <div className="content__detail__value ">{detailData.note}</div>
                        </div>
                    </TabPanel>
                    <TabPanel header={<span><MdDevicesOther className="p-mr-2"/>Thông tin thiết bị</span>}>
                        <DataTable
                            rowHover
                            value={detailData.deviceinFo}
                            dataKey="id"
                            emptyMessage="Không có data"
                            className="p-datatable-gridlines table-word-break p-datatable-responsive-demo"
                        >
                            <Column body={renderRowIndex} header="#" className="stt-table" />
                            <Column field="id_device" body={deviceBodyTemplate} header="Tên thiết bị" sortable />
                            <Column field="amount" header="Số lượng" sortable body={amountBodyTemplate}/>
                            <Column field="note" header="Ghi chú" sortable body={noteBodyTemplate}/>
                        </DataTable>
                    </TabPanel>
                </TabView>
            </Dialog>
        </div >
    )
}

DetailRoomMeeting.propTypes = {
    detailData: PropTypes.object,
}
DetailRoomMeeting.defaultProps = {
    detailData: {
        name: "",
        place: "",
        capacity: "",
        owner: "",
        note: "",
    },
}
export default (DetailRoomMeeting);
