import { Chart } from "primereact/chart";
import { useIsMountedRef } from "helpers/customHook/useIsMountedRef";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import DashboardServices from "services/DashboardServices";

export const Home = (props) => {
    const [listData, setListData] = useState({
        label: [],
        dataWeek: [],
        dataMonth: [],
    });

    const basicData = {
        labels: listData.label,
        datasets: [
            {
                label: "Số lịch đặt trong tuần",
                backgroundColor: "#42A5F5",
                data: listData.dataWeek,
            },
            {
                label: "Số lịch đặt trong tháng",
                backgroundColor: "#FFA726",
                data: listData.dataMonth,
            },
        ],
    };
    const isMountedRef = useIsMountedRef();

    const getDashboardData = async () => {
        try {
            const response = await serviceDashboard.get();
            if (isMountedRef) {
                setListData({
                    label: response.dataRoomName,
                    dataWeek: response.numInWeek,
                    dataMonth: response.countMonth,
                });
            }
        } catch (error) { }
    };
    useEffect(() => {
        getDashboardData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const serviceDashboard = new DashboardServices();


    const getLightTheme = () => {
        let basicOptions = {
            responsive: true,
            legend: {
                labels: {
                    fontColor: "#495057",
                },
            },
            scales: {
                xAxes: [
                    {
                        ticks: {
                            fontColor: "#495057",
                        },
                    },
                ],
                yAxes: [
                    {
                        ticks: {
                            fontColor: "#495057",
                            min: 0,
                            stepSize: 1,
                        },
                    },
                ],
            },
            title: {
                display: true,
                text: "Biểu đồ lịch đặt các phòng họp",
                fontSize: 16,
            },
        };

        return {
            basicOptions,
        };
    };
    const { basicOptions } = getLightTheme();
    return (
        <div className="home">
            <div className="features">
                <p>
                    Congratulations!{" "}
                    <span role="img" aria-label="celebrate" className="p-mx-2">
                        🎉
                    </span>{" "}
                    Chào mừng bạn đến phần mềm quản lý phòng họp của CyberLotus.
                </p>
                <div className="p-grid ">
                    <div className="p-col-12 p-xl-8 chart-home">
                        <Chart
                            type="bar"
                            data={basicData}
                            options={basicOptions}
                            // className="p-ml-xl-5 p-mr-xl-5 p-pl-xl-5 p-pr-xl-5"
                        />
                    </div>
                    <div className="p-col-12 p-xl-4 p-grid ">
                        <div className="p-col-12 p-lg-4 p-xl-6">
                            <div className="feature-card">
                                <img
                                    alt="opensource"
                                    src="showcase/images/react-opensource.png"
                                />
                                <div className="feature-card-detail">
                                    <span className="feature-name">
                                        ĐẶT LỊCH HỌP ONLINE
                                    </span>
                                    <p>
                                        Bạn có thể đặt phòng{" "}
                                        <a
                                            href="/#/lich-dat-cua-toi/tao-moi"
                                            className="layout-content-link"
                                            target="_self"
                                        >
                                        Tại đây
                                        </a>
                                        , để đặt phòng trực tuyến nhanh chóng và
                                        chính xác.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-lg-4 p-xl-6">
                            <div className="feature-card ">
                                <img
                                    alt="themes"
                                    src="showcase/images/react-themes.png"
                                />
                                <div className="feature-card-detail">
                                    <span className="feature-name">
                                        QUẢN LÝ - HỖ TRỢ PHÒNG HỌP
                                    </span>
                                    <p>
                                        Nắm bắt thông tin đặt phòng của tất cả
                                        mọi người. Sẵn sàng chuẩn bị chu đáo và
                                        chuẩn xác.{" "}
                                        <a
                                            href="/#/lich-dat"
                                            className="layout-content-link"
                                            target="_self"
                                        >
                                            Xem tất cả lịch họp
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-lg-4 p-xl-6">
                            <div className="feature-card">
                                <img
                                    alt="templates"
                                    src="showcase/images/react-templates.png"
                                />
                                <div className="feature-card-detail">
                                    <span className="feature-name">
                                        TIỆN ÍCH - DỄ DÀNG
                                    </span>
                                    <p>
                                        Dễ dàng sử dụng với giao diện thân
                                        thiện, xem hướng dẫn sử dụng{" "}
                                        <a
                                            href="/#/lich-dat-cua-toi"
                                            className="layout-content-link"
                                            target="_self"
                                        >
                                            tại đây
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(Home);
