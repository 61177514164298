import Axios from "axios";
import { useApp } from "context/AppContext";
// refresh token
import { refreshTokenSetup } from "helpers/refreshToken";
import React from "react";
import { useGoogleLogin } from "react-google-login";
import { authenticationService } from "services/AuthenticationService";
import clientId from "helpers/constant/clientId";

function LoginHooks(props) {
    const BASE_URL = process.env.REACT_APP_BASE_API_URL;
    // const { setAuthData } = props;
    const { showAlert } = useApp();
    const onSuccess = async (res) => {
        let data = res.profileObj;
        data.tokenId = res.tokenId;
        // khong can mat khau truyen vao
        refreshTokenSetup(res);
        const user = await authenticationService.loginGG(data);
        if (user === null) {
            localStorage.clear();
            showAlert("warn", "Đăng nhập thất bại", "Có lỗi xảy ra");
            let auth2 = window.gapi.auth2.getAuthInstance();
            auth2.signOut().then(function () {
                console.log("Bạn đã đăng xuất.");
            });
        } else {
            if (user.status === 2) {
                showAlert("warn", "Đăng nhập thất bại", user.message);
            } else {
                const url = BASE_URL + "tai-khoan/getUserInfo";
                Axios.get(`${url}`, {
                    headers: {
                        Authorization: "Bearer " + user.token,
                    },
                })
                    .then(function (e) {
                        props.setAuthData(e.data);
                    })
                    .catch(function (e) {
                        showAlert("warn", "Thất bại", "Có lỗi xảy ra");
                        console.error(e);
                    });
                window.location.hash = "/";
            }
        }
    };

    const onFailure = (res) => {
        console.log("Login google thất bại");
        // showAlert("warn", "Đăng nhập thất bại", 'Đăng nhập google thất bại');
    };

    const { signIn } = useGoogleLogin({
        onSuccess,
        onFailure,
        clientId,
        isSignedIn: true,
        accessType: "offline",
        // responseType: 'code',
        // prompt: 'consent',
    });

    return (
        <div
            onClick={signIn}
            className="btn-log-gg pointer p-button p-button-raised p-button-text"
        >
            <img
                src="showcase/images/google.svg"
                alt="google login"
                className="icon-google"
            ></img>
            <span className="text-gg">Đăng nhập bằng Google</span>
        </div>
    );
}

export default LoginHooks;
