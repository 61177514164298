import FetchAPI from "../helpers/FetchAPI";
import { TypeMethodConstantEnum } from "../helpers/MethodAPIConstant";

class UploadFileService extends FetchAPI {
    //Lấy danh sách khách hàng
    public async uploadFile(data) {
        let url = `tai-file/uploadLocal`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }
}
export default UploadFileService;
