const notification = {
    CREATE_SUCCESS: 'Tạo mới thành công',
    CREATE_FAIL: 'Tạo mới thất bại',
    UPDATE_SUCCESS: 'Cập nhật thành công',
    UPDATE_FAIL: 'Cập nhật thất bại',
    DELETE_SUCCESS: 'Xóa thành công',
    DELETE_FAIL: 'Xóa thất bại',
    SUCCESS: 'Thành công',
    FAIL: 'Thất bại',
    MESSAGE_ERROR: 'Có lỗi xảy ra',
    MESSAGE_VALIDATE: 'Kiểm tra lại dữ liệu nhập',
};
export default notification;
