import { AiOutlineFile, AiOutlineFileExcel, AiOutlineFilePdf, AiOutlineFileWord } from "react-icons/ai";
import { BiImage } from "react-icons/bi";
import { FaRegFilePowerpoint } from "react-icons/fa";

function getIconFile(file, typeView = true) {
    try {
        if (!file) return;
        let fileName = file.name;
        // let extension = fileName.split('.').pop().toLowerCase();
        let extension = fileName.substr(fileName.lastIndexOf('.') + 1);
        file.icon = <AiOutlineFile />;
        if (['jpg', 'png', 'jpeg', 'pjpeg'].includes(extension)) {
            if (typeView) {
                file.typeFile = 1;
            }
            file.icon = <BiImage color="#28AAE5" />;
        }
        if (extension === "pdf") {
            if (typeView) {
                file.typeFile = 1;
            }
            file.icon = <AiOutlineFilePdf color="red" />;
        }
        if (['doc', 'docx'].includes(extension)) {
            file.icon = <AiOutlineFileWord color="#1462BA" />;
        }
        if (['xls', 'xlsx'].includes(extension)) {
            file.icon = <AiOutlineFileExcel color="green" />;
        }
        if (['pptx', 'ppt'].includes(extension)) {
            file.icon = <FaRegFilePowerpoint color="red" />;
        }
    } catch (error) {
        console.error('error: ', error);
    }
}
export default getIconFile;
