import FetchAPI from "../helpers/FetchAPI";
import { TypeMethodConstantEnum } from "../helpers/MethodAPIConstant";

class UserService extends FetchAPI {
    //Lấy danh sách khách hàng
    public async getAll(data) {
        let url = `tai-khoan/getAllUser`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }
    public async getAllByType(data) {
        let url = `tai-khoan/getAllUserByType`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }
    public async getListUser(data) {
        let url = `tai-khoan/index`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }

    public async onCreateUser(data) {
        let url = `tai-khoan/create`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }

    public async onEditUser(data) {
        let url = `tai-khoan/update`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }

    public async onDeleteUser(data) {
        let url = `tai-khoan/delete`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }

    public async onResetPassword(data) {
        let url = `tai-khoan/reset-password`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }

    public async onDeleteMultiple(data) {
        let url = `tai-khoan/deleteMultiple`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }

    public async getListRole() {
        let url = `vai-tro/getAllRole`;
        return await this.request(url, {}, TypeMethodConstantEnum.GET);
    }

    public async onChangePassword(data) {
        let url = `tai-khoan/change-password`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }

    public async getUserInfo(data = {}) {
        let url = `tai-khoan/getUserInfo`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }

    public async updateCurrentUser(data) {
        let url = `tai-khoan/updateCurrentUser`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }

    public async getLogHistoryUser(data) {
        let url = `lich-su/current?page=${data}`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }
}
export default UserService;
