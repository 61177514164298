import { Chips } from "primereact/chips";
import { Dropdown } from "primereact/dropdown";
import { useApp } from "context/AppContext";
import checkPermission from "helpers/checkPermisson";
import notification from "helpers/constant/notification";
import permissions from "helpers/constant/permissions";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import ConfigServices from "services/ConfigServices";
import RoleServices from "services/RoleServices";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { Password } from "primereact/password";
import { Checkbox } from "primereact/checkbox";

export const Config = (props) => {
    const emptyConfig = {
        version: "",
        admin_url: "",
        url_server_node: "",
        url_api_gen_pdf: "",
        default_time_meeting: null,
        default_min_time_split: null,
        default_time_notify_mail: null,
        queue_mail_port: "",
        queue_mail_host: "",
        queue_mail_name: "",
        queue_mail_username: "",
        queue_mail_password: "",
        role_id: null,
        password_default: "",
        domain_login: [],
        queue_mail_status: 2,
        queue_mail_status_show: false,
        email_send:""
    };
    const { showAlert } = useApp();
    const serviceConfig = new ConfigServices();
    const serviceRole = new RoleServices();

    const [listRole, setListRole] = useState([]);
    const [listData, setListData] = useState(emptyConfig);
    const [errData, setErrData] = useState({});
    // check quyền
    const [typeRole, setTypeRole] = useState(1);
    const [activeIndex, setActiveIndex] = useState([0]);
    const defaultTimeMeeting = [
        { name: "30 phút", key: 1 },
        { name: "1 giờ", key: 2 },
        { name: "1 giờ 30 phút ", key: 3 },
        { name: "2 giờ ", key: 4 },
    ];
    const defaultMinTimeSplit = [
        { name: "5 phút", key: 1 },
        { name: "10 phút", key: 2 },
        { name: "15 phút ", key: 3 },
        { name: "30 phút ", key: 4 },
    ];
    const [selectedCategory, setSelectedCategory] = useState(
        defaultTimeMeeting[1]
    );
    const [selectedItem, setSelectedItem] = useState(defaultMinTimeSplit[1]);
    const [selectedTimeMail, setSelectedTimeMail] = useState(
        defaultTimeMeeting[1]
    );

    const getListRole = async () => {
        const result = await serviceRole.getAll();
        if (result) {
            setListRole(result.data);
        }
    };

    useEffect(() => {
        getListRole();
        getConfig();
        let typePer = checkPermission(permissions.DEVICE_UPDATE);
        setTypeRole(typePer);
        // eslint-disable-next-line
    }, []);

    const updateField = (data, field) => {
        setListData({
            ...listData,
            [field]: data,
        });
    };

    const handleChangeSelect = (e) => {
        setSelectedCategory(e.value);
        setListData({ ...listData, [e.target.name]: e.value.key });
    };

    const handleChangeSelect1 = (e) => {
        setSelectedItem(e.value);
        setListData({ ...listData, [e.target.name]: e.value.key });
    };
    const handleChangeSelectTimeMail = (e) => {
        setSelectedTimeMail(e.value);
        setListData({ ...listData, [e.target.name]: e.value.key });
    };
    const onUpdateConfig = async () => {

        try {
            let newListData = { ...listData };

            if (listData.role_id) {
                let role_id = listData.role_id.code;
                newListData = { ...newListData, role_id };
            }
            if (newListData.queue_mail_status_show === true) {
                newListData.queue_mail_status = 1;
            } else {
                newListData.queue_mail_status = 2;
            }
            const result = await serviceConfig.update(newListData);
            const { error, message } = result;
            if (error) {
                showAlert(
                    "error",
                    notification.UPDATE_FAIL,
                    "Kiểm tra lại dữ liệu nhập"
                );
                setErrData(error);
            } else {
                showAlert("success", notification.UPDATE_SUCCESS, message);
                getConfig();
                setErrData("");
            }
        } catch (error) {
            showAlert("error", notification.UPDATE_FAIL, "Có lỗi xảy ra");
        }
    };

    const getConfig = async () => {
        try {
            const result = await serviceConfig.getAll();
            if (result) {
                const { data } = result;
                let newData = { ...data };

                const resultRole = await serviceRole.getAll();
                let listAllRole = resultRole.data;
                let role_id = listAllRole.filter(
                    (e) => e.code === data.role_id
                )[0];
                newData.role_id = role_id;

                if (result.data.domain_login === null) {
                    newData.domain_login = [];
                } else {
                    let domain_login = JSON.parse(newData.domain_login);
                    newData.domain_login = domain_login;
                }
                if (newData.queue_mail_status === 1) {
                    newData.queue_mail_status_show = true
                } else {
                    newData.queue_mail_status_show = false;
                }
                setListData(newData);
                defaultTimeMeeting.forEach((answer) => {
                    if (answer.key === result.data.default_time_meeting) {
                        setSelectedCategory(answer);
                    }
                    if (answer.key === result.data.default_time_notify_mail) {
                        setSelectedTimeMail(answer);
                    }
                });
                defaultMinTimeSplit.forEach((answer) => {
                    if (answer.key === result.data.default_min_time_split) {
                        setSelectedItem(answer);
                    }
                });
            }
        } catch (error) {
            showAlert("error", notification.UPDATE_FAIL, notification.FAIL);
        }
    };

    return (
        <div className="Config">
            <div className="content-section implementation">
                <div className="card">
                    <div className="card-header">
                        <h5>THIẾT LẬP CHUNG</h5>
                    </div>
                    <div className="">
                        <Accordion
                            multiple
                            activeIndex={activeIndex}
                            onTabChange={(e) => {
                                setActiveIndex(e.index);
                            }}
                        >
                            <AccordionTab header="Cấu hình chung">
                                <div className="p-fluid p-grid p-formgrid">
                                    <div className="p-field p-col-12 p-md-4">
                                        <label htmlFor="version" className="label-bold">Version</label>
                                        <InputText
                                            id="version"
                                            value={listData.version}
                                            onChange={(e) =>
                                                updateField(
                                                    e.target.value,
                                                    "version"
                                                )
                                            }
                                        />
                                        <span
                                            id="username2-help"
                                            className="p-invalid p-d-block"
                                        >
                                            {errData.version}
                                        </span>
                                    </div>
                                    <div className="p-field p-col-12 p-md-4">
                                        <label htmlFor="role_id" className="label-bold">Quyền mặc định</label>
                                        <Dropdown
                                            optionLabel="name"
                                            value={listData.role_id}
                                            options={listRole}
                                            onChange={(e) =>
                                                updateField(
                                                    e.target.value,
                                                    "role_id"
                                                )
                                            }
                                            filter
                                        />
                                        <span
                                            id="username2-help"
                                            className="p-invalid p-d-block"
                                        >
                                            {errData.role_id}
                                        </span>
                                    </div>
                                    <div className="p-field p-col-12 p-md-4 ">
                                        <label className="label-bold" htmlFor="password_default">Mật khẩu mặc định</label>
                                        <Password
                                            id="password_default"
                                            value={listData.password_default}
                                            onChange={(e) =>
                                                updateField(
                                                    e.target.value,
                                                    "password_default"
                                                )
                                            }
                                            toggleMask
                                            feedback={false}
                                        />
                                        <span
                                            id="username2-help"
                                            className="p-invalid p-d-block"
                                        >
                                            {errData.password_default}
                                        </span>

                                    </div>
                                    <div className="p-field p-col-12 ">
                                        <label htmlFor="domain_login">Domain login</label>
                                        <Chips id="domain_login" value={listData.domain_login}
                                            onChange={(e) => updateField(e.value, 'domain_login')}
                                            // onChange={(e) => setDataChips(e.value)}
                                            separator=","
                                        />
                                        <span
                                            id="username2-help"
                                            className="p-invalid p-d-block"
                                        >
                                            {errData.domain_login}
                                        </span>
                                    </div>
                                </div>
                            </AccordionTab>
                            <AccordionTab header="Cấu hình thời gian">
                                <div className="p-fluid p-grid p-formgrid">
                                    <div className="p-field p-col-12 ">
                                        <div className="label-bold">
                                            Thời lượng cuộc họp mặc định
                                        </div>
                                        <label />
                                        <label >
                                            Là khoảng thời gian mặc định khi đặt một
                                            cuộc họp bất kì. VD: cuộc họp kéo dài 30
                                            phút
                                        </label>
                                        <div className="p-fluid p-grid ">
                                            {defaultTimeMeeting.map((category) => {
                                                return (
                                                    <div
                                                        key={`time-meeting-${category.key}`}
                                                        className="p-col-12 p-md-3 p-mt-2"
                                                    >
                                                        <RadioButton
                                                            inputId={`time-meeting-${category.key}`}
                                                            name="default_time_meeting"
                                                            value={category}
                                                            onChange={
                                                                handleChangeSelect
                                                            }
                                                            checked={
                                                                selectedCategory.key ===
                                                                category.key
                                                            }
                                                        />
                                                        <label
                                                            className="pointer p-ml-2"
                                                            htmlFor={category.name}
                                                        >
                                                            {category.name}
                                                        </label>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <div className="p-field p-col-12 ">
                                        <div className="label-bold" >
                                            Khoảng thời gian chia nhỏ nhất
                                        </div>
                                        <labelr />
                                        <label >
                                            Khoảng thời gian giữa các khung giờ được
                                            gợi ý khi đặt phòng
                                        </label>
                                        <div className="p-fluid p-grid">
                                            {defaultMinTimeSplit.map((category) => {
                                                return (
                                                    <div
                                                        key={`time-split-${category.key}`}
                                                        className="p-col-12 p-md-3 p-mt-2"
                                                    >
                                                        <RadioButton
                                                            inputId={`time-split-${category.key}`}
                                                            name="default_min_time_split"
                                                            value={category}
                                                            onChange={
                                                                handleChangeSelect1
                                                            }
                                                            checked={
                                                                selectedItem.key ===
                                                                category.key
                                                            }
                                                        />
                                                        <label
                                                            className="pointer p-ml-2"
                                                            htmlFor={category.name}
                                                        >
                                                            {category.name}
                                                        </label>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <div className="p-field p-col-12 ">
                                        <div className="label-bold">
                                            Khoảng thời gian thông báo trước khi họp
                                        </div>
                                        <labelr />
                                        <label >
                                            Khoảng thời gian thông báo gửi mail
                                            trước khi họp
                                        </label>
                                        <div className="p-fluid p-grid ">
                                            {defaultTimeMeeting.map((category) => {
                                                return (
                                                    <div
                                                        key={`time-mail-${category.key}`}
                                                        className="p-col-12 p-md-3 p-mt-2"
                                                    >
                                                        <RadioButton
                                                            inputId={`time-mail-${category.key}`}
                                                            name="default_time_notify_mail"
                                                            value={category}
                                                            onChange={
                                                                handleChangeSelectTimeMail
                                                            }
                                                            checked={
                                                                selectedTimeMail.key ===
                                                                category.key
                                                            }
                                                        />
                                                        <label
                                                            className="pointer p-ml-2"
                                                            htmlFor={category.name}
                                                        >
                                                            {category.name}
                                                        </label>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </AccordionTab>
                            <AccordionTab header="Cấu hình mail">
                                <div className="w-xs-100 w-md-50 p-fluid">
                                    <div className="p-grid p-field">
                                        <div className="p-col-12 p-md-3  p-text-md-right p-mb-2">
                                            <label htmlFor="queue_mail_host" className="label-bold">Host</label>
                                        </div>
                                        <div className="p-col-12 p-md-9">
                                            <InputText className="inp-width" id="queue_mail_host" value={listData.queue_mail_host}
                                                onChange={(e) => updateField(e.target.value, 'queue_mail_host')} name="queue_mail_host" />
                                        </div>
                                    </div>
                                    <div className="p-grid p-field">
                                        <div className="p-col-12 p-md-3  p-text-md-right p-mb-2">
                                            <label htmlFor="queue_mail_name" className="label-bold">Tài khoản</label>
                                        </div>
                                        <div className="p-col-12 p-md-9">
                                            <InputText className="inp-width" id="queue_mail_name" value={listData.queue_mail_name}
                                                onChange={(e) => updateField(e.target.value, 'queue_mail_name')} name="queue_mail_name" />
                                        </div>
                                    </div>
                                    <div className="p-grid p-field">
                                        <div className="p-col-12 p-md-3  p-text-md-right p-mb-2">
                                            <label htmlFor="queue_mail_password" className="label-bold">Mật khẩu</label>
                                        </div>
                                        <div className="p-col-12 p-md-9">
                                            <Password className="inp-width" id="queue_mail_password" value={listData.queue_mail_password}
                                                onChange={(e) => updateField(e.target.value, 'queue_mail_password')} type="password" name="queue_mail_password"
                                                toggleMask
                                                feedback={false}
                                            />
                                        </div>
                                    </div>
                                    <div className="p-grid p-field">
                                        <div className="p-col-12 p-md-3  p-text-md-right p-mb-2">
                                            <label htmlFor="email_port" className="label-bold">Cổng</label>
                                        </div>
                                        <div className="p-col-12 p-md-9">
                                            <InputText className="inp-width" id="queue_mail_port" value={listData.queue_mail_port}
                                                onChange={(e) => updateField(e.target.value, 'queue_mail_port')} name="queue_mail_port" />
                                        </div>
                                    </div>
                                    <div className="p-grid p-field">
                                        <div className="p-col-12 p-md-3  p-text-md-right p-mb-2">
                                            <label htmlFor="email_send" className="label-bold">Email gửi đi</label>
                                        </div>
                                        <div className="p-col-12 p-md-9">
                                            <InputText className="inp-width" id="email_send" value={listData.email_send}
                                                onChange={(e) => updateField(e.target.value, 'email_send')} name="email_send" />
                                        </div>
                                    </div>
                                    <div className="p-grid p-field">
                                        <div className="p-col-12 p-md-3  p-text-md-right p-mb-2">
                                            <label htmlFor="queue_mail_username" className="label-bold">Tên đại diện </label>
                                        </div>
                                        <div className="p-col-12 p-md-9">
                                            <InputText className="inp-width" id="queue_mail_username" value={listData.queue_mail_username}
                                                onChange={(e) => updateField(e.target.value, 'queue_mail_username')} name="queue_mail_username" />
                                        </div>
                                    </div>
                                    <div className="p-grid p-field">
                                        <div className="p-col-12 p-md-3  p-text-md-right p-mb-2">
                                        </div>
                                        <div className="p-col-12 p-md-9">
                                            <Checkbox inputId="queue_mail_status_show" name="queue_mail_status_show" checked={listData.queue_mail_status_show}
                                                onChange={(e) => updateField(e.checked, 'queue_mail_status_show')}
                                            />
                                            <label className="padding-left-10 pointer" htmlFor="queue_mail_status_show">  Sử dụng SSL</label>
                                        </div>
                                    </div>
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>

                    <div className="p-text-center p-mt-3">
                        <React.Fragment>
                            {typeRole === 1 && (
                                <>
                                    <Button
                                        label="Lưu"
                                        icon="pi pi-check"
                                        className="p-button-raised"
                                        onClick={onUpdateConfig}
                                    />
                                </>
                            )}
                        </React.Fragment>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default withRouter(Config);
